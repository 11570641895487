import {
    DELAY_ONE_SECOND,
    image_boxing,
    IMAGE_CLOUD_MAX,
} from "../Common/RegExValidate"
import { priceline_image_boxing, loadingImage } from '../Common/RegExValidate'
import './HotelImageSlider.css'
import Rating from '@material-ui/lab/Rating'
import ReviewsPricelineFeature from "../Common/Establishment/ReviewsPricelineFeature"
import EstablishmentMap from './EstablishmentMap'
import HotelImageSlider from './HotelImageSlider'
import Image from "../Common/Image"
import { PhotoVideoIcon } from '../Common/SVGBank'
import React from 'react'
import * as Actions from '../../../redux/actions'
import { SRLWrapper } from "simple-react-lightbox"
import SimpleReactLightbox from "simple-react-lightbox" // Import Simple React Lightbox
import { connect, useSelector } from 'react-redux'
import AddPhotoComponent from "../Common/AddPhotoComponent"
// import { CameraRegulerIcon } from '../Common/SVGBank'
import { Star } from '../Common/MemberActions/Star';
import { environment } from "../../../Environments/environment"
import { PostAsyncHelper } from "../../../redux/interfaces/types"
import { getHotelMapImage } from "./hotelServices"

import { useParams } from 'react-router';





const TopBarSlider_ = ({props, fetchMuvHotelPhotos,sliderImages, establishment, screenSize, isMobile, switchFeatureContainer, activeId, timelineStatus,
    review_rating_desc, reviewsAverage, setMenu, paddingLeft, paddingRight, sliderIndex }) => {

    const slideIndex = sliderIndex !== undefined ? sliderIndex : 0
    const [imgIndex, setImgIndex] = React.useState(0);
    const [, setState] = React.useState({});
    const [open, setOpen] = React.useState(true)
    const [hotelMapUrl, setMapUrl] = React.useState();
    var searchParams = new URLSearchParams(document.location.search.substring(1));
    const findBy = searchParams.get("findBy")
    const [hotelPhotos, setHotelPhotos] = React.useState();
    const [index, setIndex] = React.useState(-1);


    const hotelReviews = useSelector(state => state.HotelBooking.reviews);
    const reviewData = hotelReviews?.results;
    const goodReview = reviewData?.length > 0 ? reviewData?.find((review)=> review?.good_description?.length > 0 && review?.average_rating && Number(review?.average_rating)>7):null;
    const params= useParams()
    const establishmentPricelineID = params?.establishmentPricelineID;
  
  

    React.useEffect(() => {
        setImgIndex(-1)
        setTimeout(
            () => setImgIndex(sliderIndex !== undefined ? sliderIndex : 0)
            , 100)
    }, [sliderIndex])

    const generalInfo = establishment.generalInfo

    const establishmentStarRating = establishment.generalInfo.establishmentStarRating

    const imageWidth = screenSize - (screenSize * paddingLeft) - (screenSize * paddingRight)

 
    const hotelName = establishment.generalInfo !== undefined && establishment.generalInfo !== null ? establishment.generalInfo.name : ""
    let topImageHeight = isMobile ? 250 : 450
    let topImageHeightMin = topImageHeight + 165
    let containerH = 500
    let fontDestinationName = isMobile ? "14" : "24"
    let fontIcon = isMobile ? "10" : "16"
    let widthIcon = isMobile ? "110px" : "150px"
    let heightIcon = isMobile ? "30px" : "45px"
    let fontHeightIcon = isMobile ? "17px" : "30px"
    // let fontIconCameraReguler = isMobile ? "15" : "20"
    if (topImageHeight !== undefined) {
        containerH = topImageHeight + "px"
    }


    const destinationImage = hotelPhotos !== undefined && hotelPhotos.length > 0 && hotelPhotos[imgIndex] !== undefined
        ? hotelPhotos?.[imgIndex]?.url
        : loadingImage.src + "?func=crop&w=" + Math.floor(imageWidth) + "&h=" + Math.floor(topImageHeight)
    const destinationImage2 = hotelPhotos !== undefined && hotelPhotos.length > 1 && hotelPhotos[imgIndex + 1] !== undefined
        ? hotelPhotos[imgIndex+1]?.url
        : loadingImage.src + "?func=crop&w=" + Math.floor(imageWidth) + "&h=" + Math.floor(topImageHeight)
    const display_letters = screenSize > 1024 ? 50 : 20
    const options = open && hotelPhotos !== undefined && hotelPhotos.length > 0 ? {
        thumbnails: {
            showThumbnails: true,
            thumbnailsAlignment: 'space-between',
            // thumbnailsContainerBackgroundColor: 'transparent',
            // thumbnailsContainerPadding: '0',
            // thumbnailsGap: '0 1px',
            // thumbnailsIconColor: '#ffffff',
            // thumbnailsOpacity: 0.4,
            thumbnailsSize: ['100px', '80px']
        },
        overlayColor: "rgb(25, 25, 25)",
        captionColor: "rgba(25, 188, 155, 1)",
        captionFontFamily: "Futura Md BT",
        captionFontSize: "22px",
        captionFontWeight: "300",
        captionFontStyle: "capitalize",
        buttonsBackgroundColor: "#191919",
        buttonsIconColor: "rgba(25, 188, 155, 1)",
        autoplaySpeed: 1500,
        transitionSpeed: 900,

        buttons: {
            showFullscreenButton: false,
        },
    } : {}
    let fontIconPhotoVideo = isMobile ? "18" : "25"

    const address = generalInfo !== undefined && generalInfo.address !== undefined && generalInfo.address !== null && generalInfo.address !== "" ? generalInfo.address + ", " : ""
    const country = generalInfo !== undefined && generalInfo.countryName !== undefined && generalInfo.countryName !== null && generalInfo.countryName !== "" ? generalInfo.countryName + " " : ""
    const postalCode = generalInfo !== undefined && generalInfo.postalCode !== undefined && generalInfo.postalCode !== null && generalInfo.postalCode !== "" ? generalInfo.postalCode : ""
    const city = generalInfo !== undefined && generalInfo.cityName !== undefined && generalInfo.cityName !== null && generalInfo.cityName !== "" ? generalInfo.cityName + ", " : ""
    const state = generalInfo !== undefined && generalInfo.stateName !== undefined && generalInfo.stateName !== null && generalInfo.stateName !== "" ? generalInfo.stateName + ", " : ""

    const locationURL = generalInfo !== undefined && generalInfo.address !== undefined && generalInfo.address !== null && generalInfo.address !== ""
        && generalInfo.countryName !== undefined && generalInfo.countryName !== null && generalInfo.countryName !== ""
        ? (address + city + state + country + postalCode).replace(" ", "+") : ""

    //console.log({destinationImage,locationURL,establishment,generalInfo,address});
    const [imageRef, setImageRef] = React.useState(null)


    const getGalleryImages = () => {
        if (imageRef?.click) {
            imageRef.click()
        }

    }

    React.useEffect(() => {
        const establishmentID = establishment?.generalInfo?.establishmentHPID;

        if (establishmentID && establishmentID?.length > 0) {
          const fetchHotelPhotos = async()=>{
            const photos = await fetchMuvHotelPhotos(establishmentID,findBy)
                setHotelPhotos(photos?? [])
           
          }
          fetchHotelPhotos()
        }
    }, [establishment,findBy])

    React.useEffect(() => {
        if(establishment){
            loadHotelMapImage()
        }
    }, [establishment])


    const loadHotelMapImage = React.useCallback(async () => {
        const establishmentID = establishment?.generalInfo?.establishmentPricelineID
        if (establishmentID) {
            const result = await getHotelMapImage(establishmentID);

            if (result) {
                setMapUrl(result)
            }
        }
    }, [establishment])
  
 


    return (
        <div>
            <div className="position-relative z_index_2" style={{marginTop: isMobile?30:0,  backgroundColor: "black", minHeight: { topImageHeightMin } }}>
                {imgIndex !== -1 && !isMobile && <div
                    style={{height:topImageHeight, background:"black"}}
                ><img 
                    src={destinationImage} alt={""} width="100%" height={topImageHeight}
                    onError={(event) => event.target.style.display = 'none'}
                    style={{
                        opacity: '0.8', width: "100%", objectFit: "cover", objectPosition: "center",
                        height: Math.floor(topImageHeight), width: Math.floor(imageWidth),
                        //paddingLeft: "15%", paddingRight: "15%"
                    }} /> </div>}
                {imgIndex == -1 && <img fallbackSrc={destinationImage2}
                    src={destinationImage} alt="destination" width="100%" height={topImageHeight}

                    style={{
                        opacity: '0.8', width: "100%", objectFit: "cover", objectPosition: "center",
                        height: Math.floor(topImageHeight), width: Math.floor(imageWidth),
                        //paddingLeft: "15%", paddingRight: "15%"
                    }} />}
                {hotelPhotos === undefined || hotelPhotos?.length <= 0 || hotelPhotos[imgIndex] === undefined &&
                    <div className="mask_block" style={{ height: containerH }}>
                    </div>}

                <div style={{
                    position: "absolute", top: "60px", right: "84px",
                    width: "29px", height: "28px"
                }}>
                    <div> </div>
                    {establishment.generalInfo && establishment.generalInfo.destinationID ?
                        <Star
                            unitID={establishment.generalInfo.destinationID}
                            feature={switchFeatureContainer}
                            fill={"#FFFFFF"} /> : null
                    }
                </div>

                {!isMobile && <div className="z_index_3"
                    style={{
                        top: "25px",
                        right: "25px",
                        position: "absolute",
                        width: "325px",
                        height: "400px",
                        backgroundColor: "rgb(255, 255, 255)",
                        padding: "20px",
                    }}>

                    <div className="text-left"    //futura_hv_bt_24_28
                        style={{
                            font: fontDestinationName + "px/28px Futura Hv BT",
                            //color: "#FFFFFF",
                            //textShadow: '0px 6px 6px #191919'
                        }} >{hotelName !== undefined && hotelName}
                    </div>
                    <div className="text-left"    //futura_hv_bt_24_28
                        style={{
                            font: fontDestinationName + "px/28px Futura Hv BT",
                            marginTop: 5,
                            //color: "#FFFFFF",
                            //paddingLeft: "8.5%",
                            //textShadow: '0px 6px 6px #191919'
                        }} >
                        <div style={{display:"flex"}}>{establishmentStarRating !== undefined && <Rating name="reviews_rating" style={{ color: "var(--mainGreen)", opacity: "1", lineHeight: "24px", marginRight: 5, }} precision={0.5} max={5} value={parseFloat(establishmentStarRating)} disabled={true} />}
                        <div className="futura_md_bt_11_14_black margin_bottom_5 margin_left_5" style={{ color: "#C7C7C7" }}>({establishmentStarRating})</div>
                        </div>
                        
                        <div style={{ marginTop: 0 }} className="box_2" >
                        {generalInfo !== undefined && generalInfo?.reviewsAverage ? <div style={{ display: "inline-block", font: "18px/18px Futura Md BT", }}>
                                <span style={{ color: "var(--mainGreen)", font: "18px/18px Futura Md BT", marginTop: "1.5rem" }}>{goodReview?goodReview?.average_rating: generalInfo && generalInfo?.reviewsAverage ? Number(generalInfo?.reviewsAverage)?.toFixed(1)  : ''}</span><span>/10</span></div>:""}
                            {(review_rating_desc !== undefined  ||goodReview?.review_text)&& <span style={{ color: "var(--mainGreen)",font: "18px/18px Futura Md BT", fontFamily: "Futura Md BT", marginLeft: "1rem" }}>{goodReview?goodReview?.review_text:review_rating_desc}</span>}
                        </div>

                        {(goodReview?.good_description) || (establishment.reviewsGoogleAndYelp !== undefined && establishment?.reviewsGoogleAndYelp?.priceline !== undefined &&
                            establishment?.reviewsGoogleAndYelp?.priceline !== null && establishment.reviewsGoogleAndYelp?.priceline[0]?.priceline_review_1_text !== "" )
                             ?
                            <div style={{marginTop:-20}}>
                                <ReviewsPricelineFeature setMenu={setMenu} goodReview={goodReview?.good_description ??null } reviewsPriceline={establishment?.reviewsGoogleAndYelp?.priceline} />
                            </div>:""}

                        <div style={{ flexGrow: "1", height: 150, position: "absolute", height: 94, width: 268, bottom: 20 }}>
                            {window.google !== undefined && generalInfo !== undefined && generalInfo.countryName !== "" && hotelMapUrl &&
                                establishment.generalInfo.latitude !== "" &&
                                establishment.generalInfo.latitude !== null &&
                                establishment.generalInfo.longitude !== null &&
                                establishment.generalInfo.longitude !== "" &&
                                <div onClick={() => window.open('https://www.google.com/maps/place/' + locationURL + '/@' + establishment.generalInfo.latitude + ',' + establishment.generalInfo.longitude)}>
                                    <img 
                    src={hotelMapUrl} alt={""} width="100%" height={94}
                    style={{
                        opacity: '0.8', width: "100%", objectFit: "cover", objectPosition: "center",
                        filter: "grayscale(1)"
                        //paddingLeft: "15%", paddingRight: "15%"
                    }} />
                                    {/* <EstablishmentMap
                                        zoom={13}
                                        height={94}
                                        lat={establishment.generalInfo.latitude}
                                        lng={establishment.generalInfo.longitude} /> */}
                                </div>}
                        </div>

                    </div>
                </div>}


                {hotelPhotos  !== undefined && hotelPhotos?.length > 0 && hotelPhotos[0] !== undefined
                    && hotelPhotos?.[0].url !== undefined &&  !isMobile &&
                    <HotelImageSlider timelineStatus={timelineStatus}  images={hotelPhotos}/>
                }

                {isMobile &&<div
                style={{display:"flex",overflowX:"scroll"}}>
                    {hotelPhotos?.length > 0 && hotelPhotos.map((image, index) => {
							return (
								<div key={index} style={{width: "100vw", height: 300, marginRight:4}}
									>
									<Image fallbackSrc={priceline_image_boxing("https://amhqajbken.cloudimg.io/v7/_dev_/commom/loading.gif?func=crop&w=" + Math.floor(600) + "&h=" + Math.floor(300), 600, 300)}
										key={index} 
										style={{
											//margin: 4,
											width: "100vw", objectFit: "cover", objectPosition: "center", 
											height: 300
										}}
										src={image?.url}
										//className="img_fit_cover d-block w-100"
										alt={image.description} />
								</div>
								
							)
						})}
                    
                    </div>}


                {/* //desktop */}
                {!isMobile && <div className="d-inline-block"
                    onClick={getGalleryImages}
                    style={{
                        bottom: "25%",
                        left: "25px",
                        position: "absolute",
                        zIndex: "5",
                        width: widthIcon,
                        height: heightIcon,
                        background: "#FFFFFF",
                        overflow: "hidden",
                        color: switchFeatureContainer.color,
                        border: "1px solid " + switchFeatureContainer.color,
                        borderRadius: "39px",
                        textAlign: "center",
                        paddingTop: '5px',
                        font: fontIcon + "px/" + fontHeightIcon + " Futura Md BT",
                        marginRight: "8px",
                        cursor: "pointer",
                    }}>
                    <PhotoVideoIcon width={fontIconPhotoVideo} fill={switchFeatureContainer.color} />{" View Gallery"}

                    <HotelGallery setImageRef={setImageRef} sliderImages={hotelPhotos} options={options} open={open} />

                </div>}



            </div >

            

        </div>


    )

}


const HotelGallery = ({ open, sliderImages, options, setImageRef }) => {

    return open && sliderImages !== undefined && sliderImages.length > 0 && sliderImages[0] !== undefined
        && sliderImages?.[0]?.url !== undefined &&
        <div className="hotelGallery">
            <SimpleReactLightbox>
                <div className="z_index_3  hotelGallery" >
                    <div className="box_1 text-right  "
                        style={{
                            color: "#FFFFFF",
                        }} >

                        <SRLWrapper options={options} >

                            {sliderImages.map((image, index) => {
                                return (
                                    index === 0 ?
                                        <React.Fragment>
                                            <Image
                                                key={index}
                                                style={{
                                                    position: "absolute",
                                                    left: "0%",
                                                    bottom: "0%",
                                                    opacity: "0.0"
                                                }}
                                                ref={imageClick => setImageRef(imageClick)}
                                                src={image?.url}
                                                className="img_fit_cover d-block w-100" />
                                        </React.Fragment> :
                                        <React.Fragment>
                                            <Image
                                                key={index}
                                                style={{
                                                    position: "absolute",
                                                    left: "0%",
                                                    bottom: "0%",
                                                    opacity: "0.0"
                                                }}
                                                src={image?.url}
                                                className="img_fit_cover d-block w-100"
                                            />
                                        </React.Fragment>

                                )


                            })}
                        </SRLWrapper>




                    </div>
                </div>
            </SimpleReactLightbox>
        </div>
}


const mapStateToProps = state => {
    return {
        screenSize: Math.min(state.Setting.htmlBody.bodyClientWidth, document.body.clientWidth),
        isMobile: state.Setting.is_mobile,
        activeId: state.FormBank.HotelSearch.establishment.activeId,
        sliderImages: state.FormBank.HotelSearch.establishment.sliderImages,
        //generalInfo: state.FormBank.HotelSearch.establishment.generalInfo,
        reviewsAverage: state.FormBank.HotelSearch.establishment.reviewsAverage,
        review_rating_desc: state.FormBank.HotelSearch.establishment.review_rating_desc,
        switchFeatureContainer: state.FormBank.FeatureFormat,

        establishment: state.FormBank.HotelSearch.establishment,
        sliderIndex: state.FormBank.HotelSearch.establishment.sliderIndex,

    }
}

const mapDispatchToProps = {
    fetchMuvHotelPhotos:Actions.fetchMuvHotelPhotos
}

const TopBarSlider = connect(mapStateToProps, mapDispatchToProps)(TopBarSlider_)
export default TopBarSlider
