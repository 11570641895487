import {
    DeleteIcon,
    RightArrowIcon,
    StarIcon,
} from "../SVGBank"

import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import * as Actions from '../../../../redux/actions'
import { CircularProgress, Grid } from "@material-ui/core"
import { AirplaneComponent, FlightDestination, FlightDetailComponent } from "../../FlightsPage/flightsComponents"
import { secondsToHms, tConv24 } from "../../../../utility/utility"
import '../../FlightsPage/flights.css';
import moment from "moment"
import { useHistory, useLocation } from "react-router-dom"
import { resetFlightPage, updateFlightData } from "../../FlightsPage/services/Flights.slice"
import { checkFlightAvailable, deleteFlightTimeline, getFlightsListThunk } from "../../FlightsPage/services/Flights.thunks"
import { lookUpSavedHotel, updateBookingDestination } from "../Trip/ActiveTrip.slice"

import { deleteHotelTimeline, getNearByAirport, searchHotelByNameThunk } from "../../BookingPage/PaymentPage/payment.thunk"
import { setActiveTripDestinationId, setTimelineTripDestinationOpenStatus } from "../../../../redux/actions"



const TimelineSavedFlight_ = ({ destination, destinationFlights, flight, nextFlight, switchFeatureContainer }) => {
    const departureDate = flight?.departure_date ? moment(flight.departure_date).format('MMM Do') : "";
    const arrivalDate = flight?.arrival_date ? moment(flight.arrival_date).format('MMM Do') : "";
    const history = useHistory()
    const booked = flight?.booked_booking_status === "ACCEPTED" || flight?.booked_booking_status === "Success" ||
        flight?.booked_booking_status_code === "ACCEPTED";
    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const { uuid } = useSelector(state => state.Member.authModal);
    const activeTrip = useSelector(state => state.FormBank.TimelineWizard.activeTrip);
    const activeTripId = activeTrip?.tripId;
    const fullCurrency = selectedCurrency ? selectedCurrency.toUpperCase() : "CAD";
    const email = flight?.booked_email;
    const bookingId = flight?.priceline_trip_number || flight?.booked_booking_status;
    const dispatch = useDispatch()
    const location = useLocation()
    const [checkingFlight, setChecking] = React.useState(false)
    const [flightList, setFlightList] = React.useState([])


    const [flightAvailable, setAvailable] = React.useState(true)

    const [initialCheck, setInitialCheck] = React.useState(false)
    React.useEffect(() => {

        if (nextFlight) {
            setFlightList([nextFlight, flight])
        }


    }, [nextFlight])

    React.useEffect(() => {


        setTimeout(() => {
            const ppnId = flight?.flight_json?.itinerary_data?.length > 0 &&
                flight?.flight_json?.itinerary_data[0]?.ppn_contract_bundle;

            const isAvailable = async () => {
                const res = await checkFlightAvailable(ppnId, fullCurrency, dispatch)
                setAvailable(res)
                setInitialCheck(true)
            }
            if (ppnId) {
                isAvailable()
            }
        }, 3000);


    }, [])



    const selectDestination = () => {
        dispatch(setActiveTripDestinationId(destination))
        dispatch(updateBookingDestination(destination))

    }



    const deleteFlight = () => {
        const tripDestId = flight?.trip_destinations_id;
        const flightId = flight?.id;

        const payload = {
            uuid: uuid,
            tripId: activeTripId,
            postData: {
                tripDestinationId: tripDestId,
                flightId: flightId
            },
            wait: nextFlight ? true : false
        }



        if (uuid) {
            dispatch(deleteFlightTimeline(payload))
        }

        ///if theres another flight delete both
        if (nextFlight) {
            const payload2 = {
                uuid: uuid,
                tripId: activeTripId,
                postData: {
                    tripDestinationId: tripDestId,
                    flightId: nextFlight?.id
                }
            }

            if (uuid) {
                dispatch(deleteFlightTimeline(payload2))
            }
        }

    }


    const goToBooking = () => {

        if (email && bookingId) {
            const url = `/flight/booking_details?booking_id=${bookingId}&email=${email}`;
            history.push(url.toLowerCase());
        }
    }


    const searchFlight = () => {

        let flights = []
        let flightListCopy = [...flightList]
        flightListCopy = flightListCopy?.sort((a, b) => a?.return_leg_from_trip_destination_id === null ? -1 : 1)      ///place return at bottom of list always

        if (flight?.search_type === "round_trip" && flightListCopy && flightListCopy?.length > 1) {
            flightListCopy.forEach((flight, index) => {
                flights.push({
                    "airportFrom": {
                        "city": flight?.departure_city,
                        "latitude": "",
                        "city_code": flight?.departure_iata,
                        "type": "main",
                        "iata": flight?.departure_iata,
                        "airport": "All Airports"
                    },
                    "airportTo": {
                        "city": flight?.arrival_city,
                        "city_code": flight?.arrival_iata,
                        "type": "main",
                        "iata": flight?.arrival_iata,
                        "airport": "All Airports"
                    },
                    "departureDateFormat": flight?.departure_date ? new Date(moment(flight?.departure_date).format("YYYY-MM-DD 00:00")) : new Date().toDateString(),
                    "departureDate": flight?.departure_date ? new Date(moment(flight?.departure_date).format("YYYY-MM-DD 00:00")) : new Date().toDateString(),
                    "availableSelectDate": new Date().toDateString(),
                    "positionId": "d-" + index
                })
            })

        }
        else {
            flights = [
                {
                    "airportFrom": {
                        "city": flight?.departure_city,
                        "latitude": "",
                        "city_code": flight?.departure_iata,
                        "type": "main",
                        "iata": flight?.departure_iata,
                        "airport": "All Airports"
                    },
                    "airportTo": {
                        "city": flight?.arrival_city,
                        "city_code": flight?.arrival_iata,
                        "type": "main",
                        "iata": flight?.arrival_iata,
                        "airport": "All Airports"
                    },
                    "departureDateFormat": flight?.departure_date ? new Date(moment(flight?.departure_date).format("YYYY-MM-DD 00:00")) : new Date().toDateString(),
                    "departureDate": flight?.departure_date ? new Date(moment(flight?.departure_date).format("YYYY-MM-DD 00:00")) : new Date().toDateString(),
                    "availableSelectDate": new Date().toDateString(),
                    "positionId": "d-0"
                }]

        }


        const flightMode = flight?.search_type || 'one_way';
        const numPassengers = flight?.flight_json?.itinerary_data?.length > 0 ?
            flight?.flight_json?.itinerary_data[0]?.price_details?.number_of_passengers : 1;
        const dataToBookingFlights = {
            flightMode: flightMode,
            cabinClass: 'economy',
            numberAdult: numPassengers,
            numberChild: '0',
            flights: flights,
            currency: fullCurrency

        }
        const path = location.pathname;


        const flightDates = [...flights]?.map((flight) => {
            const formatDate = moment.parseZone(flight?.departureDate)?.format("YYYY-MM-DD")?.toString()?.split("-");
            const dateString = `${formatDate[2]}${formatDate[0] < 10 ? "0" + formatDate[0] : formatDate[0]}${formatDate[1] < 10 ? "0" + formatDate[1] : formatDate[1]}`
            return `${flight?.airportFrom?.iata}-${flight?.airportTo?.iata}-${formatDate?.join('') ?? ""}`
        })

        let flightUrl = `/flight/search?flightMode=${flightMode}&cabinClass=${flight?.cabinClass || 'economy'}&numberAdult=${numPassengers}&numberChild=${flight?.numberChild || 0}&flights=${flightDates?.toString()}`;
        // dispatch(updateFlightData(dataToBookingFlights))
        // dispatch(getFlightsListThunk(dataToBookingFlights))

        dispatch(resetFlightPage())


        history.push(flightUrl)
    }


    const isAvailable = async () => {
        const ppnId = flight?.flight_json?.itinerary_data?.length > 0 &&
            flight?.flight_json?.itinerary_data[0]?.ppn_contract_bundle;

        setChecking(true)
        const res = await checkFlightAvailable(ppnId, fullCurrency, dispatch)
        setAvailable(res)
        setChecking(false)
        return res;
    }


    const bookFlight = async () => {
        let available = false;

        if (!flightAvailable && initialCheck) {
            available = await isAvailable();
        }

        if (available || (flightAvailable && initialCheck)) {
            selectDestination()
            const ppnId = flight?.flight_json?.itinerary_data?.length > 0 &&
                flight?.flight_json?.itinerary_data[0]?.ppn_contract_bundle;
            history.push(`/package?bundleId=${ppnId}`)

        }
        else {
            searchFlight()
        }
    }

    const currency = `${flight?.price_display_currency}`
    const price = `${flight?.price_display_symbol}${flight?.price_display_total_fare}`
    const confirmationNumber = flight?.booking_number || flight?.priceline_trip_number;


    const stopCount = flight?.flight_json?.itinerary_data && flight?.flight_json?.itinerary_data[0]?.slice_data ? Number(flight?.flight_json?.itinerary_data[0]?.slice_data[0]?.info.connection_count) : 0;
    let notesArray = [];
    const stopNotes = flight?.flight_json?.itinerary_data && flight?.flight_json?.itinerary_data[0]?.slice_data ? flight?.flight_json?.itinerary_data[0]?.slice_data[0]?.flight_data?.map((flight) => flight?.info?.notes) : null
    const notesData = stopNotes && stopNotes?.length > 0 ? stopNotes.forEach((notes) => {
        notesArray = notesArray?.concat(notes)
    }) : null;
    notesArray = notesArray?.map((note) => {
        let text = secondsToHms(note?.duration)
        const airport = note?.value?.match(/\(([^)]+)\)/) && note?.duration ? note?.value?.match(/\(([^)]+)\)/)[1] : null;
        if (airport) {
            text = `${airport} ${text}`
        }
        return text;
    });
    notesArray = notesArray.filter(n => n);
    const durationText = notesArray && notesArray?.length > 0 ? notesArray?.length > 1 ? notesArray?.join(" - ") : notesArray : null


    return (
        <div >
            <Grid container style={{
                height: 'auto', padding: '20px 0px 0px 0px', backgroundColor: "var(--faintBlue)",
                marginLeft: "0px",
                marginRight: "0px",
                marginBottom: "0px", marginTop: "20px", overflow: 'auto'
            }}>

                <div style={{width:"96%",position:"relative",zIndex:1,
                    
                }}>
                    <img src={flight?.airline_logo} height={25} alt="logo" style={{ background: "transparent", paddingLeft: 20 }} />
                    <div style={{
                        cursor: "pointer", textAlign: "right",display:"flex",
                       float:"right",
                       marginTop:5
                    }}>
                        {booked ?
                            <div style={{
                                font: "12px/16px Futura Lt BT",
                                color: "var(--mainBlack)",
                            }}>
                                Confirmation #:<span style={{ font: "14px/14px Futura Hv BT", }}>
                                    {confirmationNumber}
                                </span>
                            </div>
                            : <div style={{
                                font: "12px/16px Futura Lt BT",
                                color: "var(--mainBlue)",
                            }}>
                                {currency ?? ""}   <span style={{ font: "16px/14px Futura Hv BT", }}>
                                    {price ?? ""}
                                </span>
                            </div>}
                        {!booked && <div style={{
                        cursor: "pointer",
                        paddingLeft:10,
                        marginTop:-5
                        }}
                            onClick={deleteFlight}
                        >
                            <DeleteIcon width={14} height={16} style={{ marginRight: "0px" }} />
                        </div>}
                    </div>

                </div>




                <Grid container spacing={0} className="flightTimeline" >
                    {/* <div className='line' style={{ zIndex: 0 }}></div> */}

                    <Grid item xs={5} md={5} lg={5} xl={5} style={{ width: 100, zIndex: 1, marginLeft: -15 }} >
                        <div style={{ textAlign: 'right', background: "transparent" }}>
                            <FlightDestination flightText={'from'} airportCode={flight.departure_iata}
                                color="var(--mainBlue)"
                                country={flight.departure_city} date={departureDate} time={tConv24(flight.departure_time)}
                                fromTimeline={true} align="left"
                            />
                        </div>



                    </Grid>

                    <Grid item xs={2} md={2} lg={2} xl={2} style={{ width: 100, zIndex: 1 }}>
                        <div onClick={searchFlight} style={{ marginTop: 49, cursor: "pointer" }}>
                            <AirplaneComponent
                                height={35}
                                color="var(--mainBlue)"
                                background={"transparent"} time={secondsToHms(flight.overall_duration)} />
                        </div>
                    </Grid>
                    <Grid item xs={5} md={5} lg={5} xl={5} style={{ marginLeft: 6, zIndex: 1 }}>
                        <div style={{ textAlign: 'left', background: "transparent" }}>
                            <FlightDestination flightText={'to'} airportCode={flight.arrival_iata}
                                country={flight.arrival_city} date={arrivalDate} time={tConv24(flight.arrival_time)}
                                fromTimeline={true} align="right"
                                color="var(--mainBlue)" />
                        </div>
                    </Grid>

                </Grid>
                {stopCount > 0 ? <div style={{
                    font: "14px/12px Futura Lt BT",
                    color: "var(--mainBlue)",
                    textAlign: "left",
                    paddingLeft: 15, paddingBottom: 15
                }}>
                    {stopCount}{stopCount > 1 ? " Stops" : " Stop"}
                    {durationText ? "(" + durationText + ")" : ""}
                </div> : ""}
            </Grid>

            <div style={{ borderTop: "2px solid white", backgroundColor: "var(--faintBlue)", margin: "0px 0px 5px 0px", paddingBottom: 10 }}>
                {booked && <div onClick={goToBooking} style={{
                    font: "14px/16px Futura Hv BT", cursor: "pointer", padding: 10, marginTop: 10,
                    borderRadius: 35, color: "#19BC9B", background: "white",
                    border: "2px solid #19BC9B", textAlign: "center",
                    width: 150,
                    marginRight: "auto", marginLeft: "auto"
                }}>
                    Flight Details
                </div>}


                {!booked && !checkingFlight && <div onClick={bookFlight} style={{
                    font: "14px/16px Futura Hv BT", cursor: "pointer", padding: 10, marginTop: 10,
                    borderRadius: 35, color: "#19BC9B", background: "white",
                    border: "2px solid #19BC9B", textAlign: "center",
                    width: 150,
                    marginRight: "auto", marginLeft: "auto"
                }}>
                    Book Now
                </div>}
                {!booked && !checkingFlight && !flight?.return_leg_from_trip_destination_id && <div onClick={searchFlight}
                    style={{
                        color: "#274674 ",
                        cursor: "pointer",
                        float: "right",
                        font: "12px/15px Futura lt BT",
                        paddingRight: 5,
                        marginTop: -27
                    }}>
                    see other flights
                </div>}
            </div>


            {!booked && !flightAvailable && <div
                style={{
                    color: "red",
                    cursor: "pointer",
                    textAlign: "right",
                    font: "12px/15px Futura lt BT",
                    paddingBottom: "5px",
                    marginTop: 0
                }}>
                This flight is not available anymore
            </div>}
            {/* {!booked && !checkingFlight && !flight?.return_leg_from_trip_destination_id && <div onClick={searchFlight}
                style={{
                    color: "#274674 ",
                    cursor: "pointer",
                    textAlign: "right",
                    font: "12px/15px Futura lt BT",
                    paddingBottom: "15px",
                    marginTop: -2
                }}>
                see other available flights
            </div>} */}
            {checkingFlight && !booked && <div
                style={{
                    color: "#274674 ",
                    cursor: "pointer",
                    textAlign: "right",
                    font: "12px/15px Futura lt BT",
                    paddingBottom: "15px",
                    marginTop: -2
                }}>
                <CircularProgress disableShrink size={20}
                    style={{
                        color: "#6A9EEC",
                        marginLeft: "5px"
                    }} />
            </div>}


        </div>
    )
}

const mapStateToProps = (state) => ({
    switchFeatureContainer: state.FormBank.FeatureFormat,

})

const mapDispatchToProps = {
}

const TimelineSavedFlight = connect(mapStateToProps, mapDispatchToProps)(TimelineSavedFlight_)
export default TimelineSavedFlight
