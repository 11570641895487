import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'
import { useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import { FlightSearchComponent } from './FlightsSearch'
import { FlightListComponent } from './FlightsList'
import { ScrollTopLazyLoading } from '../Common/ScrollTopLazyLoading';
import { Backdrop, CircularProgress, Fab, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { AngleUpIcon } from '../Common/SVGBank';
import { FlightSortComponent } from './flightsSort';
import { updateFlightData } from './services/Flights.slice';
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


const FlightsContainer_ = ({ lang, bodyClientWidth, pageYOffset, is_mobile, timelineWizardStatus }) => {
    const search = useLocation().search;
     const query = new URLSearchParams(search);
     const oneclick = useSelector(state => state.PersonalizedTrip.oneclick.active)
     const hotelPPn = useSelector(state => state.PersonalizedTrip.oneclick.hotelPpn)
    const classes = useStyles();
    const dispatch = useDispatch()
    let width;
    let marginLeft,marginRight;
    const history = useHistory()
    const theme = useTheme();
     const mdScreen = useMediaQuery(theme.breakpoints.only('md'));

    // React.useEffect(() => {
    //     if (search) {
    //         const searchFlight = {
    //             flightMode: query.get('mode'),
    //             numberAdult: query.get('adults'),
    //             numberChild: query.get('children'),
    //             cabinClass:  query.get('cabin')
    //         }
    //         dispatch(updateFlightData(searchFlight))
    //     }
    // }, [search])

    const skipToPackage = ()=>{
        if(oneclick){
                  
            history.push(`/package`) 
        }
    }
   
    let positionScrollToTopLazyLoading = ""
    if (!isNaN(is_mobile)) {
   
        width = is_mobile? mdScreen?"80%" :  '100%' :  '67%';
        marginLeft = is_mobile ? mdScreen? "13.5%":'0%' :  timelineWizardStatus ? '530px'  :  '15.5%';
        marginRight =0
        positionScrollToTopLazyLoading = is_mobile ? "" : (timelineWizardStatus ? "10%" : "10%")
    }
    const loading = useSelector(state => state.Flights.loading);
 
    return (
        <div>
            <div style={{
                position: "relative",
                width: width,
                marginLeft: marginLeft,
                marginRight:marginRight
            }}>
                <FlightSearchComponent bodyClientWidth={bodyClientWidth} />
                <div id="back_to_top_flights"></div>
               {oneclick && <div style={{ font: "18px/36px Futura Lt BT",margin:"20px 0px",cursor:"pointer",textAlign:'right' }} onClick={skipToPackage}>
                Skip to Hotel Payment <FontAwesomeIcon icon={faArrowRight} style={{marginRight: "5px"}} /> 
                    </div>}
                <FlightSortComponent is_mobile={is_mobile}/>
                <FlightListComponent bodyClientWidth={bodyClientWidth} />

                <Backdrop className={classes.backdrop} open={loading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>


            <ScrollTopLazyLoading targetId={"#back_to_top_flights"} rightPosition={positionScrollToTopLazyLoading}>
                <Fab size="small" aria-label="scroll back to top" style={{
                    outline: "0px",
                    backgroundColor: '#19BC9B',
                }}>
                    <AngleUpIcon fill={"var(--mainWhite)"} style={{ marginLeft: "3px" }} />
                </Fab>
            </ScrollTopLazyLoading>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        lang: state.Setting.lang,
        is_mobile: state.Setting.is_mobile,
        lazyLoading: state.FormBank.HotelSearch.lazyLoading,
        hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
        bookingForm: state.FormBank.BookingForm,
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
        pageYOffset: state.Setting.screenVertical.pageYOffset,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        timelineWizardStatus: state.FormBank.TimelineWizard.status,
    }
}

const mapDispatchToProps = {
    setTripDisplayMode: Actions.setTripDisplayMode,
    setPageYOffset: Actions.setPageYOffset
}

const FlightsContainer = connect(mapStateToProps, mapDispatchToProps)(FlightsContainer_)
export default FlightsContainer
