import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'
import "./TimelinePage.css"
import { connect, useDispatch, useSelector } from 'react-redux'
import TimelineWizard from '../Common/TimelineWizard/TimelineWizard'
import { TripDestinationTabs } from '../Common/TimelineWizard/TripDestinationTabs'
import { CircularProgress, Grow, Popper } from '@material-ui/core'
import { TripStartDateTextField } from '../Common/FormComponentsBank'
import TripEllipsisTimeline from '../Common/Trip/TripEllipsisTimeline'
import { HeaderMobile } from '../Header/HeaderMobile'
import { BookingFormHeader } from '../Header/BookingFormHeader'
import PriceLineBookingFormElement from '../Common/BookingForm/PriceLineBookingFormElement'
import { TimesIcon } from '../Common/SVGBank'



const TimelinePageContainer_ = ({ setBookingFormStatus,logo_width,open,savedTrip, activeTrip, featureListDisplayFormat, lang, bodyClientWidth, pageYOffset, is_mobile, timelineWizardStatus }) => {
    const anchorRef = React.useRef(null)
    const isRoadTrip = savedTrip && savedTrip?.trip && savedTrip?.trip?.tripTravelCategory?.code === "road";

    const handleForceClose = event => {
        setBookingFormStatus(false)
    }

    return (
        <div style={{ paddingTop: 0,paddingBottom:100 }}  ref={anchorRef}>
           
            <div className="timelinePageTop"
            style={{paddingTop:is_mobile? 25:0}}>
                {activeTrip.tripName !== "" && <div style={{ marginBottom: "5px", }}>

                    <div style={{
                        marginBottom: "5px", font: "18px/18px Futura Lt BT",
                        color: "var(--frameBlack)",
                        textAlign: "center",
                    }}>
                        {activeTrip?.tripName}
                    </div>
                </div>}

                <div style={{ position: "absolute", top: "5px", right: "10px", cursor: "pointer" }} className="timelineEllipsis" >
                    <TripEllipsisTimeline
                        color={"var(--mainGrey)"} />
                </div>

                <div className="timelinePageDate" style={{ textAlign: 'center', font: "16px/19px Futura Lt BT", color: "#6A9EEC", marginLeft: "0px" }}>
                    <TripStartDateTextField />
                </div>
                <div style={{ textAlign: 'center', paddingBottom: 10 }}>
                    <span style={{ font: "16px/19px Futura Lt BT" }}>Cost if booked today:</span>
                    {!activeTrip.tripCost.fetching
                        && activeTrip.tripCost !== undefined
                        && activeTrip.tripCost !== undefined
                        && activeTrip.tripCost.cost !== undefined
                        && activeTrip.tripCost.cost !== null ?
                        <span style={{ font: "16px/19px Futura Md BT", color: "var(--frameBlack)", marginLeft: "5px" }}>{activeTrip.tripCost.cost.currency_symbol + "" + activeTrip.tripCost.cost.cost}</span>
                        :
                        <CircularProgress
                            disableShrink
                            size={15}
                            style={{
                                color: "#6A9EEC",
                                marginLeft: "5px"
                            }} />
                    }
                </div>
                {isRoadTrip && <div style={{
                    font: "14px/16px Futura Md BT", color: '#D8521D', textAlign: "center", background: 'white', padding: 5, width: 136,
                    border: '0.5px solid #C7C7C7', position: "absolute", left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto',
                    top: '7%'
                }}>
                    ROAD TRIP
                            </div>}
                <hr style={{ border: "1px solid rgb(232, 232, 232)", height: 0, margin: 0 }} />
                <TripDestinationTabs />
            </div>

            <TimelineWizard listDisplayFormat={featureListDisplayFormat} hideTab={true} />

        </div>
    )
}


const mapStateToProps = state => {
    return {
        lang: state.Setting.lang,
        is_mobile: state.Setting.is_mobile,
        savedTrip: state.ActiveTrip,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,
        featureListDisplayFormat: state.Feature.feature.featureListDisplayFormat,
        lazyLoading: state.FormBank.HotelSearch.lazyLoading,
        hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
        bookingForm: state.FormBank.BookingForm,
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
        pageYOffset: state.Setting.screenVertical.pageYOffset,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        timelineWizardStatus: state.FormBank.TimelineWizard.status,
        open: state.FormBank.BookingForm.status,
        logo_width: state.Setting.appMenu.logo.width,
    }
}

const mapDispatchToProps = {
    setTripDisplayMode: Actions.setTripDisplayMode,
    setPageYOffset: Actions.setPageYOffset,
    setBookingFormStatus: Actions.setBookingFormStatus,
    setBookingFormActiveTab: Actions.setBookingFormActiveTab,
}

const TimelinePageContainer = connect(mapStateToProps, mapDispatchToProps)(TimelinePageContainer_)
export default TimelinePageContainer
