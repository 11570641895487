import * as Actions from '../../../redux/actions'

import { Authorization } from "../Common/Authorization"
import { BarsIcon } from '../Common/SVGBank'
import { BookingFormHeader } from './BookingFormHeader'
import { Currency } from '../Common/Currency'
import { GeneralSearchBar } from '../Common/GeneralSearch/GeneralSearchBar'
import { Language } from '../Common/Language'
import Logo from "./Logo"
import { Navbar } from 'react-bootstrap'
import React from 'react'
import StarredIcon from "../Common/MemberActions/StarredIcon"
import { connect } from 'react-redux'
import { member_area_router } from "../Common/RegExValidate"

/* header component */
class Header_ extends React.Component {
  componentDidMount() {
    this.props.settAppMenuBackGroundColor(this.props.bg_color)
    this.props.setterAppMenuTopBarTextColor(this.props.text_color)
  }
  render() {
    const { app_menu_height, is_mobile,memberActions,hideStars,  backGroundColor, header_height,authModal, appMenuBookingButtonStatus, stars, switchFeatureContainer, switch_container } = this.props
    const getColor = () =>{
      return "#19BC9B";
    }
    const lengthStars = stars.length

    const notLoggedIn = () =>{
      return authModal.uuid === ""
  }


    return (
      <React.Fragment>
        {!is_mobile && <Navbar sticky="top" expand="lg" className="pt-0 pb-0"
          style={{
            width:"100%",
            height: header_height === "half" ? app_menu_height / 2 + "px" : app_menu_height + "px",
            boxShadow: appMenuBookingButtonStatus ? "0px 6px 12px #0000001A" : "",
            // top: Math.ceil(-app_menu_height / 2),
            backgroundColor: backGroundColor
          }}>
          <div>
            <Logo header_height={header_height} />
            <div style={{
              position: "absolute",
              top: "-100px",
              left: "-100px",
              color: "white",
            }}><BarsIcon width={15} height={15} /></div>
          </div>
          <div className="d-flex w-100" style={{
            position: "sticky",
            top: "12px",
          }}>

            {appMenuBookingButtonStatus && <BookingFormHeader />}
            <div
              style={{
                flexGrow: "1",
                textAlign: "right",
              }}>
              <div style={{
                display: "inline-flex",
              }}>
                <div className="margin_left_30_menu"><GeneralSearchBar /></div>
                <div className="margin_left_30_menu"><Authorization fontColor={getColor()} font={is_mobile?"12px/24px Futura Md BT": "16px/36px Futura Md BT"} /></div>
                <div className="margin_left_30_menu"><Currency /></div>
                {/* <div className="margin_left_30_menu"><Language /></div> */}
                { !hideStars && lengthStars > 0 && !notLoggedIn() && <div style={{ marginLeft: "50px" }}><StarredIcon color={getColor()} lengthStars={lengthStars} /></div>}
              </div>
            </div>
          </div>
        </Navbar>}
      </React.Fragment >
    )
  }
}


const mapStateToProps = (state) => {
  //console.log(state)
  return {
    app_menu_height: state.Setting.appMenu.app_menu_height,
    backGroundColor: state.Setting.appMenu.backGroundColor,
    appMenuBookingButtonStatus: state.Setting.appMenu.appMenuBookingButtonStatus,
    is_mobile: state.Setting.is_mobile,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    stars: state.MemberActions.fetchStarSets.stars,
    authModal: state.Member.authModal,
    switch_container: state.Member.switch_container,
    memberActions: state.MemberActions.memberActionSets.memberActions,
  }
};
const mapDispatchToProps = {
  settAppMenuBackGroundColor: Actions.settAppMenuBackGroundColor,
  setterAppMenuTopBarTextColor: Actions.setterAppMenuTopBarTextColor,
};
const Header = connect(mapStateToProps, mapDispatchToProps)(Header_)
export default Header;