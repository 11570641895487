import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie';
import { environment } from '../../../../Environments/environment';
import { setTripToTimeline } from '../../../../redux/actions';
import {
    PostAsyncHelper, SET_TIMELINE_WIZARD_STATUS, ALERT_BARS_HANDLE,
} from '../../../../redux/interfaces/types';



export const createTripThunk = createAsyncThunk(
    'personalizedTrip/createTrip',
    async (tripObj, thunkAPI) => {
        // let apiURL = environment.apiUrl + "memberarea/trip/generatePersonalizedTrip/" + tripObj.lang + "/" + tripObj.uuid;
        let apiURL = environment.apiUrl + "muv-v2/personalization/generateAiPersonalizedTrip"

        if(tripObj?.keepHotel){
         apiURL = environment.apiUrl + "muv-v2/personalization/generateAiActivities"
        }

        const response = await PostAsyncHelper(apiURL, tripObj);
        const hideTimeline = tripObj?.hideTimeline;
        const dispatch = thunkAPI.dispatch;

        try {
        const trip = response?.data?.result?.trip
        if (trip) {
            
          
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "success",
                message: trip.trip_name|| trip?.tripName + " created"
            })
            const tripID = trip.id || trip?.tripID || null;
            if(tripID){
                await dispatch(setTripToTimeline(tripID, tripObj?.uuid))
          
                dispatch({
                    type: SET_TIMELINE_WIZARD_STATUS,
                    status: hideTimeline?false:true
                })
        
               
             
            }
        
            return trip;
        }
        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "error",
            message: 'Could not create trip, please try again later'
        })
        return thunkAPI.rejectWithValue(false) 
    }
    catch(err){
        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "error",
            message: err.response.data || 'Could not create trip, please try again later'
        })
        return thunkAPI.rejectWithValue(false) 
    }
    }
)


const initialState ={
    modalOpen: false,
    tripAdded: false,
    aiTrip:{
        data:{
            destinations:null,
            destinationText:null,
            startDate:null,
            duration:null,
            startDateFormat:null,
            travellers:null
        },
        loading:false,
        modalOpen:false,
        saved:null
    },
    useAiHotel:false,
    oneclick:{
        active: false,
        hotelPpn: null,
        flightPpn:null,
        hotel:null,
        flight:null,
        package:{
            index:0,
            data:null,
        },
        tripId:null,
    },
    stayTrip:false,
    stayTripData:null
}
export const createPersonalizedTrip = createSlice({
    name: 'personalizedTrip',
    initialState: initialState,
    reducers: {
        toggleModal: (state) => {
            state.modalOpen = !state.modalOpen;
        },
        toggleAiTripLoading: (state,action) => {
            state.aiTrip.loading = action.payload;
        },
        toggleAiModal: (state,action) => {
            if(state.aiTrip.modalOpen){
                state.aiTrip = initialState.aiTrip;
            }
            else{
                state.aiTrip.data = action.payload;
                state.aiTrip.modalOpen = !state.aiTrip.modalOpen;
            }

        },
        toggleOneclick: (state,action) => {
            state.oneclick.active = action.payload;
        },
        updateOneclickHotel: (state,action) => {
            state.oneclick.hotel = action.payload;
        },
        updateOneclickTripId: (state,action) => {
            state.oneclick.tripId = action.payload;
        },
        updateOneclickFlight: (state,action) => {
            state.oneclick.flight = action.payload;
        },
        updatePackageData: (state,action) => {
            state.oneclick.package.data = action.payload;
        },
        updateOneclickPackageIndex: (state,action) => {
            state.oneclick.package.index = action.payload;
        },
        updateFlightPPn: (state,action) => {
            state.oneclick.flightPpn = action.payload;
        },
        updateHotelPPn: (state,action) => {
            state.oneclick.hotelPpn = action.payload;
        },
        updateSavedTrip: (state,action) => {
            state.aiTrip.saved = action.payload;
        },
        updateStayTrip: (state,action) => {
            state.stayTrip = action.payload;
        },
        updateUseAiHotel: (state,action) => {
            state.useAiHotel = action.payload;
        },
        updateStayTripData: (state,action) => {
            state.stayTripData = action.payload;
        },
    },
    extraReducers: {
        [createTripThunk.pending]: (state, action) => {
        },
        [createTripThunk.fulfilled]: (state, action) => {
            state.tripAdded = true;
        },
    },
})

// Action creators are generated for each case reducer function
export const {toggleAiTripLoading,updateStayTripData,updateOneclickTripId,updateOneclickFlight,updateStayTrip,
    updateOneclickHotel,updateUseAiHotel,updatePackageData,updateOneclickPackageIndex, toggleAiModal, updateSavedTrip,toggleModal,toggleOneclick,updateFlightPPn,updateHotelPPn } = createPersonalizedTrip.actions

export const PersonalizedTripReducer = createPersonalizedTrip.reducer;