import * as Actions from '../../../../redux/actions'

import {
    DELAY_ZERO_SECOND,
    MAX_ACTIVITY_IN_DAY,
} from "../RegExValidate"

import ActivityDuration from "./ActivityDuration"
import ActivityEllipsis from "./ActivityEllipsis"
import { ActivityStartTime } from "./ActivityStartTime"
import EstablishmentName from '../Establishment/EstablishmentName'
import {
    Image,
} from 'react-bootstrap'
import Rating from '@material-ui/lab/Rating'
import React from 'react'
import TimeToReach from './TimeToReach'
import { connect } from 'react-redux'
import { image_boxing } from '../RegExValidate'
import ActivitiesForm from './ActivitiesForm'
import { DeleteIcon, EllipsisVIcon } from '../SVGBank'
import { MoreHoriz } from '@material-ui/icons'
import { hexToRGB } from '../../../../utility/utility'

export const Activities_ = ({lengthDestinationDays,startDragging,setDragging, setTimelineTripDestinationOpenStatus,deleteActivityToDayOfTripDestinationInTrip, lang, uuid_auth, draggingEstablishment, activeDay, dragDropActivityInActivitiesOfTripDestinationInTrip, lengthActivitiesInDestinationDay, draggingActivity, switchFeatureContainer, setTimelineDraggingActivity, tripDestinationId, dayInTrip, activeTrip, dayInTripDestination, destination, addActivityInActivitiesOfTripDestinationInTrip, activity, activitiesInDestinationDayEstablishmentIds, indexActivity, activitiesInDestinationDayIds }) => {
    const tripId = activeTrip.tripId
    const [establishmentPlaceHolder, setEstablishmentPlaceHolder] = React.useState(false)
    const [establishmentPlaceHolderBorder, setEstablishmentPlaceHolderBorder] = React.useState("1px solid var(--mainGreen)")
    const [activityPlaceHolder, setActivityPlaceHolder] = React.useState(false)
   
    const [activityPlaceHolderBorder, setActivityPlaceHolderBorder] = React.useState("1px solid rgb(25, 188, 155)")
    const uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID"

    const [showEdit, setEdit] = React.useState(false)

    const previous_establishmentId = indexActivity > 0 && activitiesInDestinationDayEstablishmentIds[indexActivity - 1] ? activitiesInDestinationDayEstablishmentIds[indexActivity - 1] : ""
    const next_establishmentId = activitiesInDestinationDayEstablishmentIds[indexActivity] !== undefined ? activitiesInDestinationDayEstablishmentIds[indexActivity] : ""
    const current_activityId = activitiesInDestinationDayIds[indexActivity] !== undefined ? activitiesInDestinationDayIds[indexActivity] : ""
    const next_activityId = activitiesInDestinationDayIds[indexActivity - 1] !== undefined ? activitiesInDestinationDayIds[indexActivity - 1] : ""
    const checkIsMaxDay = draggingActivity.draggingActivity !== undefined && draggingActivity.draggingActivity !== null && Number(dayInTripDestination) !== Number(draggingActivity.draggingActivity.activityDayInDestination) && MAX_ACTIVITY_IN_DAY === activitiesInDestinationDayIds.length
    const switchFeatureContainerColorLight =  hexToRGB("#19BC9B",0.3) 

    const establishmentIdDataTransfer = draggingEstablishment.draggingEstablishment !== undefined && draggingEstablishment.draggingEstablishment !== null ? draggingEstablishment.draggingEstablishment.establishmentID : ""
   
    const onDropInActivities = (event, selectedDestination, dayInTripDestination) => {
        establishmentIdDataTransfer !== undefined
            && establishmentIdDataTransfer !== ""
            && establishmentIdDataTransfer !== previous_establishmentId
            && establishmentIdDataTransfer !== next_establishmentId
            && addActivityInActivitiesOfTripDestinationInTrip(lang, uuid, selectedDestination.td_id, dayInTripDestination, establishmentIdDataTransfer, tripId, "", dayInTripDestination, dayInTripDestination, activitiesInDestinationDayIds, indexActivity)

        const activityEstablishmentIdDataTransfer = draggingActivity.draggingActivity !== undefined && draggingActivity.draggingActivity !== null ? draggingActivity.draggingActivity.activityEstabID : ""
        activityEstablishmentIdDataTransfer !== undefined
            && activityEstablishmentIdDataTransfer !== ""
            && activityEstablishmentIdDataTransfer !== previous_establishmentId
            && activityEstablishmentIdDataTransfer !== next_establishmentId && !checkIsMaxDay
            && dragDropActivityInActivitiesOfTripDestinationInTrip(lang, uuid, selectedDestination.td_id, tripId, draggingActivity.draggingActivity.activityID, dayInTripDestination, draggingActivity.draggingActivity.activityDayInDestination, activitiesInDestinationDayIds, indexActivity, draggingActivity.dragActivityIndexInDay, draggingActivity.draggingActivity.tripDestinationId, draggingActivity.dragActivityIdsInDay)
   
            setActivityPlaceHolder(false)
            setEstablishmentPlaceHolder(false)
            setDragging(null)
        }

    const onDragEnterActivities = (event) => {
        establishmentIdDataTransfer !== undefined
            && establishmentIdDataTransfer !== "" && setEstablishmentPlaceHolder(true)
        establishmentIdDataTransfer !== undefined
            && establishmentIdDataTransfer !== ""
            && establishmentIdDataTransfer !== previous_establishmentId
            && establishmentIdDataTransfer !== next_establishmentId ? setEstablishmentPlaceHolderBorder("1px solid rgb(25, 188, 155)") : setEstablishmentPlaceHolderBorder("1px solid rgb(220, 53, 69)")

        const activityEstablishmentIdDataTransfer = draggingActivity.draggingActivity !== undefined && draggingActivity.draggingActivity !== null ? draggingActivity.draggingActivity.activityEstabID : ""
        activityEstablishmentIdDataTransfer !== undefined
            && activityEstablishmentIdDataTransfer !== "" && setActivityPlaceHolder(true)
        activityEstablishmentIdDataTransfer !== undefined
            && activityEstablishmentIdDataTransfer !== ""
            && activityEstablishmentIdDataTransfer !== previous_establishmentId && !checkIsMaxDay
            && activityEstablishmentIdDataTransfer !== next_establishmentId ? setActivityPlaceHolderBorder("1px solid rgb(25, 188, 155)") : setActivityPlaceHolderBorder("1px solid rgb(220, 53, 69)")

        event.preventDefault()
        event.stopPropagation()
        setDragging(true)

    }

    const onDragStartActivity = (event, activity, indexActivity, destinationIdOfDragActivity, activitiesInDestinationDayIds) => {
        const dragDeleteActivity = {
            lang: lang,
            uuid: uuid,
            tripDestinationId: tripDestinationId,
            activityID: activity.activityID,
            tripId: tripId,
        }
        setTimelineDraggingActivity(indexActivity, destinationIdOfDragActivity, dragDeleteActivity, activity, activitiesInDestinationDayIds)
        event.dataTransfer.setDragImage(document.getElementById('drag_activity_' + activity.activityID), 0, 0)

        setActivityPlaceHolder(false)
        setDragging(true)

        window.scrollTo(0, 0);

    }

    const onDragEndActivity = (event,index) => {
        event.preventDefault()
        event.stopPropagation()
        setEstablishmentPlaceHolder(false)
            setActivityPlaceHolder(false)
        setTimelineDraggingActivity(0, "", null, null, [])
        setDragging(null)
      
    }

    const onDragOverActivities = (event,) => {
        event.preventDefault()
        event.stopPropagation()
    }

    const delay = (() => {
        let timer = 0
        return (callback, ms) => {
            clearTimeout(timer)
            timer = setTimeout(callback, ms)
        }
    })()

    const cancelEdit = ()=>{
        setEdit(false)
    }
  

    React.useEffect(() => {
        setEstablishmentPlaceHolder(false)
        setActivityPlaceHolder(false)
    }, [activeTrip.activities, activeTrip.addedActivityID])


       React.useEffect(() => {
           if(startDragging === null){
            setEstablishmentPlaceHolder(false)
            setActivityPlaceHolder(false)
           }
    }, [startDragging])

    // React.useEffect(() => {
    //     if(activeTrip.addedActivityID){
    //         const activitiesInActiveDestinationDay = activeTrip.activities && activeTrip.activities.filter(a => a.activityDayInDestination === activeDay.dayInTripDestination && a.tripDestinationId === activeDay.tripDestinationId)
    //         let activeActivity = activitiesInActiveDestinationDay && activitiesInActiveDestinationDay.find(activity => activity.activityEstabID === activeTrip.addedActivityID);
    //         let ID = activeActivity && activeActivity.activityID
    //         if( activeActivity &&  activeActivity.tripDestinationId){
    //             setTimelineTripDestinationOpenStatus(activeActivity.tripDestinationId,"OPENSELECTED")
    //         }
           
    //         ID && document.getElementById("drag_activity_" + ID) !== null && document.getElementById("drag_activity_" + ID).scrollIntoView({ behavior: 'smooth', block: 'center' })
      
    //     }
    // }, [activeTrip.addedActivityID])

    const deleteActivity = () => {
        deleteActivityToDayOfTripDestinationInTrip(lang, uuid, activity?.tripDestinationId, activity?.activityID, tripId)
    }


    const onDragLeaveActivities = (event) => {
        delay(function () {
            setEstablishmentPlaceHolder(false)
            setActivityPlaceHolder(false)
            setTimelineDraggingActivity(0, "", null, null, [])
            setDragging(null)
        }, DELAY_ZERO_SECOND)
        event.preventDefault()
        event.stopPropagation()
    }

    const timeToReachColor = "var(--mainGreen)";
    const timeToReachFillIcon = "#7F7F7F";

 
    return (
        <React.Fragment>

        {startDragging && establishmentPlaceHolder && establishmentPlaceHolderBorder === "1px solid rgb(25, 188, 155)" && <div style={{
            border: "1px solid rgba(25, 188, 155, 0.5)", display: "flex", margin: "10px", backgroundColor: "rgba(25, 188, 155, 0.1)", height: "80px"
        }}
            draggable={true}
            onDragLeave={(e) => (destination.id === draggingEstablishment.draggingEstablishmentDestinationId && lengthActivitiesInDestinationDay < MAX_ACTIVITY_IN_DAY) || (destination.id === draggingActivity.dragActivityInDestination && draggingActivity.draggingActivity !== null) ? onDragLeaveActivities(e) : {}}
            onDrop={(e) => (destination.id === draggingEstablishment.draggingEstablishmentDestinationId && lengthActivitiesInDestinationDay < MAX_ACTIVITY_IN_DAY) || (destination.id === draggingActivity.dragActivityInDestination && draggingActivity.draggingActivity !== null) ? onDropInActivities(e, destination, dayInTripDestination) : {}}
            onDragOver={(e) => (destination.id === draggingEstablishment.draggingEstablishmentDestinationId && lengthActivitiesInDestinationDay < MAX_ACTIVITY_IN_DAY) || (destination.id === draggingActivity.dragActivityInDestination && draggingActivity.draggingActivity !== null) ? onDragOverActivities(e) : {}}
        />}
        {startDragging && establishmentPlaceHolder && establishmentPlaceHolderBorder === "1px solid rgb(220, 53, 69)" && <div style={{
            border: "1px solid rgba(220, 53, 69, 0.5)", margin: "10px", height: "80px", backgroundColor: "rgba(220, 53, 69, 0.1)"
        }}
            onDragLeave={(e) => (destination.id === draggingEstablishment.draggingEstablishmentDestinationId && lengthActivitiesInDestinationDay < MAX_ACTIVITY_IN_DAY) || (destination.id === draggingActivity.dragActivityInDestination && draggingActivity.draggingActivity !== null) ? onDragLeaveActivities(e) : {}}
        ></div>}
      
      
         <div onDragEnter={(e) => (destination.id === draggingEstablishment.draggingEstablishmentDestinationId && lengthActivitiesInDestinationDay < MAX_ACTIVITY_IN_DAY) || (destination.id === draggingActivity.dragActivityInDestination && draggingActivity.draggingActivity !== null)
            ? (draggingEstablishment.draggingEstablishment !== null && !establishmentPlaceHolder ? onDragEnterActivities(e) :
                ((draggingActivity.draggingActivity === null || draggingActivity.draggingActivity.activityID === current_activityId || draggingActivity.draggingActivity.activityID === next_activityId) || activityPlaceHolder ? {} : onDragEnterActivities(e))
            ) : {}}>
           <TimeToReach activity={activity} fillIcon={timeToReachFillIcon} indexActivity={indexActivity} color={timeToReachColor} />
        </div>
        {startDragging &&activityPlaceHolder && activityPlaceHolderBorder === "1px solid rgb(25, 188, 155)" && <div style={{
            border: "1px solid rgba(25, 188, 155, 0.5)", display: "flex", margin: "10px", backgroundColor: "rgba(25, 188, 155, 0.1)", height: "80px"
        }}
            draggable={true}
            onDragLeave={(e) => (destination.id === draggingEstablishment.draggingEstablishmentDestinationId && lengthActivitiesInDestinationDay < MAX_ACTIVITY_IN_DAY) || (destination.id === draggingActivity.dragActivityInDestination && draggingActivity.draggingActivity !== null) ? onDragLeaveActivities(e) : {}}
            onDrop={(e) => (destination.id === draggingEstablishment.draggingEstablishmentDestinationId && lengthActivitiesInDestinationDay < MAX_ACTIVITY_IN_DAY) || (destination.id === draggingActivity.dragActivityInDestination && draggingActivity.draggingActivity !== null) ? onDropInActivities(e, destination, dayInTripDestination) : {}}
            onDragOver={(e) => (destination.id === draggingEstablishment.draggingEstablishmentDestinationId && lengthActivitiesInDestinationDay < MAX_ACTIVITY_IN_DAY) || (destination.id === draggingActivity.dragActivityInDestination && draggingActivity.draggingActivity !== null) ? onDragOverActivities(e) : {}}
        />}
       
       {startDragging &&activityPlaceHolder && activityPlaceHolderBorder === "1px solid rgb(220, 53, 69)" && <div style={{
            border: "1px solid rgba(220, 53, 69, 0.5)", margin: "10px", height: "80px", backgroundColor: "rgba(220, 53, 69, 0.1)"
        }}
            onDragLeave={(e) => (destination.id === draggingEstablishment.draggingEstablishmentDestinationId && lengthActivitiesInDestinationDay < MAX_ACTIVITY_IN_DAY) || (destination.id === draggingActivity.dragActivityInDestination && draggingActivity.draggingActivity !== null) ? onDragLeaveActivities(e) : {}}
        ></div>}


        <div style={{
            display: "flex",
            position: "relative",
            margin: "8px",
            border: previous_establishmentId === activity.activityEstabID ? "1px solid rgba(220, 53, 69, 0.5)" : "",
            backgroundColor: previous_establishmentId === activity.activityEstabID ? "rgba(220, 53, 69, 0.2)" : "",
        }}

            onDragStart={(e) => {
                onDragStartActivity(e, activity, indexActivity, destination.id, activitiesInDestinationDayIds)
            }}
            onDragEnd={(e) => {onDragEndActivity(e,indexActivity);}}
            onDrop={(e) => (destination.id === draggingEstablishment.draggingEstablishmentDestinationId && lengthActivitiesInDestinationDay < MAX_ACTIVITY_IN_DAY) || (destination.id === draggingActivity.dragActivityInDestination && draggingActivity.draggingActivity !== null) ? onDropInActivities(e, destination, dayInTripDestination, activity) : {}}
            draggable={true}
            className={"draggable"}

            id={"drag_activity_" + activity.activityID}
        >
            <Image className="img_fit_cover"
                src={image_boxing(activity.image, "90", "70")}
                alt="activity" width={90} height={70} />
            <div style={{ flexGrow: "1",   width: '48%', zIndex: (draggingActivity.draggingActivity === null || draggingActivity.draggingActivity.activityID !== activity.activityID) ? "2" : "", }} >
                {activity.activityEstabID !== undefined && activity.activityEstabID !== "" &&
                    <EstablishmentName
                        establishmentId={activity.activityEstabID}
                        establishmentName={activity.activityName}
                        isCustom={activity.is_custom}
                        titleClass={"box_1"}
                        titleStyle={{ cursor: 'pointer', font: "14px/16px Futura Hv BT", textAlign: "left", color: "var(--frameBlack)", padding: "0px 10px", width: "210px" }}
                    />}

                {activity.activityStarRating !== undefined && activity.activityStarRating !== 0 && <Rating style={{ color: "var(--mainGreen)", padding: "0px 10px", opacity: "1" }} name="timeline_rating" precision={0.5} max={5} value={parseFloat((Number(activity.activityStarRating)).toFixed(1))} disabled={true} size="small" />}

                {activity.activitySubTypes !== undefined && activity.activitySubTypes.length > 0 &&
                    <div className="box_1">{activity.activitySubTypes.map((subtype, subtype_idx) => {
                        return <div key={subtype_idx} style={{
                            margin: "0px 0px 10px 10px",
                            font: "14px/16px Futura Md BT",
                            color: "var(--mainGreen)",
                            backgroundColor: switchFeatureContainerColorLight ,
                            borderColor: switchFeatureContainerColorLight,
                        }}
                            className="btn_rounded"
                        >
                            {subtype.subtypeName}</div>
                    })}</div>
                }
            </div>
            <div style={{ textAlign: "right", zIndex: (draggingActivity.draggingActivity === null || draggingActivity.draggingActivity.activityID !== activity.activityID) ? "3" : "" }}>
                <ActivityEllipsis activity={activity}  indexActivity={indexActivity} lengthActivitiesInDestinationDay={lengthActivitiesInDestinationDay}
                dayInTripDestination={dayInTripDestination} lengthDestinationDays={lengthDestinationDays}
                activeDay={activeDay} destination={destination}/>
                {/* <div onClick={
                    ()=> {setEdit(true)}
                }><MoreHoriz height={'25'} fill={ "#202020"} /></div> */}
             {/* <div onClick={deleteActivity} style={{background:'white',marginRight:-10}}
                ><DeleteIcon height={'25'} fill={ "#707070"} /></div> */}
                <ActivityStartTime activity={activity} />
                <ActivityDuration activity={activity} />
            </div>

         
            {/* <div style={{
                position: "absolute",
                height: "80px",
                width: "320px",
                zIndex: "6",
                backgroundColor: (draggingActivity.draggingActivity === null || draggingActivity.draggingActivity.activityID !== activity.activityID) ? "" : "rgba(255, 255, 255, 0.5)",
            }}>
            </div> */}

        </div>
        {showEdit && <ActivitiesForm  activity={activity}
        destination={destination}
        cancelEdit={cancelEdit}/>}
    </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    draggingEstablishment: state.FormBank.TimelineWizard.draggingEstablishment,
    draggingActivity: state.FormBank.TimelineWizard.draggingActivity,
    lang: state.Setting.lang,
    uuid_auth: state.Member.authModal.uuid,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    activeDay: state.FormBank.TimelineWizard.activeDay,
    

}
}

const mapDispatchToProps = {
    addActivityInActivitiesOfTripDestinationInTrip: Actions.addActivityInActivitiesOfTripDestinationInTrip,
    dragDropActivityInActivitiesOfTripDestinationInTrip: Actions.dragDropActivityInActivitiesOfTripDestinationInTrip,
    setTimelineDraggingActivity: Actions.setTimelineDraggingActivity,
    setTimelineTripDestinationOpenStatus: Actions.setTimelineTripDestinationOpenStatus,
    deleteActivityToDayOfTripDestinationInTrip: Actions.deleteActivityToDayOfTripDestinationInTrip,
}

const Activities = connect(mapStateToProps, mapDispatchToProps)(Activities_)
export default Activities
