
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import * as Actions from '../../../redux/actions'

import { Element, Link } from 'react-scroll'
import HotelSearchFormEmbedded from "../Common/BookingForm/HotelSearchFormEmbedded"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'

import BookingBasicInfo from './BookingBasicInfo'
import BookingItems from './BookingItems'
import { Menu } from 'semantic-ui-react'
import { ALERT_BARS_HANDLE } from '../../../redux/interfaces/types'
import "./BookingContainer.css"

const BookingContainer_ = ({ props, is_mobile, establishment, timelineStatus, hotelSearchForm,
    logo_height, app_menu_height, lang, uuid, setHotelBookingState, setOpenAuthModal, booking_info, fetchHotelBooking, booking_bundles
     }) => {

    const [menu, setMenu] = React.useState("rooms")
    const dispatch = useDispatch()
    //console.log(props.match.params.establishmentPricelineID);
    
    var searchParams = new URLSearchParams(document.location.search.substring(1));
    
    searchParams.forEach((value, key) => {
        //console.log(value, key);
    });
    
    const hotel_id = searchParams.get("hotel_id")
    const check_in = searchParams.get("check_in")
    const check_out = searchParams.get("check_out")
    const rooms = searchParams.get("rooms")
    const adults = searchParams.get("adults")
    const ppn_book_bundle = searchParams.get("ppn_book_bundle")
    
    const booking_form_state = {
        hotel_id, 
        ppn_book_bundle, 
        check_in, 
        check_out, 
        rooms,
        adults
    }
    setHotelBookingState(booking_form_state)       //HotelSearch.booking_info

    
    //const breadcrumb = establishment.breadcrumb
    //const booking_info = establishment.booking_info
    let padding_l = 15
    let padding_r = 15


    if (!isNaN(is_mobile)) {
        padding_l = is_mobile ? "1" : (timelineStatus ? "31.5" : "15.5")
        padding_r = is_mobile ? "1" : (timelineStatus ? "5" : "15.5")
    }
    
    //const headerHeight = (-Math.ceil(-app_menu_height / 2))
    //const menuOffset = -headerHeight - 90
    //const experiencesExisted = establishment && establishment.experiences && establishment.experiences.length > 0
    //const neighborhoodExisted = establishment && establishment.neighborhood 

    const isLoggedIn = () => {
        return uuid && uuid.length > 0
    }

    const signIn = () => {
        setOpenAuthModal(true)
    }


    React.useEffect(() => {
       if(!isLoggedIn()){
        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "warning",
            message: "Please login to save this trip"
        })
       }
    }, [uuid])
    
    const CartComponent = ()=>{
        return booking_bundles !== undefined && booking_bundles !== null && booking_bundles.length>0 && 
                       
        
            <div style={{
                 width:is_mobile? "100%":"346px",
                 margin: is_mobile? "0 auto": "0",
                 display:is_mobile?"block":"inline-block"
             }} className="cartItem">
                {booking_bundles !== undefined && booking_bundles !== null && booking_bundles.length > 0 &&
                         booking_bundles.map((bundle, bidx) => {
                             if (bundle && bidx === booking_bundles?.length - 1) {
                                 return (
                                     <div style={{ marginBottom: 15 }} key={bidx+"cart"}  >
                                        <BookingItems booking_bundles={booking_bundles}
                                        showMultiple={false}
                                        booking_bundle={bundle} />
                                     </div>
                                 )
                             }
                             return null
                         })}
             </div>

    }

    return (
        <React.Fragment>

            <div style={{ position: "relative" ,paddingBottom:100}}>
                <div style={{ flexGrow: 1 }}>
                    {/* <div style={{
                        paddingLeft: padding_l + "%",
                        paddingRight: padding_r + "%",
                        position: "relative",
                        zIndex: "4",
                        backgroundColor: "var(--mainWhite)",
                        paddingTop: "15px",
                    }}>
                        {hotelSearchForm.check_in!=="" &&
                            <div className="background-img-row">
                            <div className={"booking_form_container"}>
                                <HotelSearchFormEmbedded embedForm={true}/> 
                            </div>
                        </div>}
                                          
                    </div> */}

                {!(uuid !== undefined && uuid !== null && uuid !== "") &&     
                    <div style={{
                        paddingLeft: padding_l + "%",
                        paddingRight: padding_r + "%",
                        position: "relative",
                        //zIndex: "4",
                        backgroundColor: "var(--mainWhite)",
                        paddingTop: is_mobile ?80:"15px",
                    }}>
                        <div style={{ font: is_mobile ? "12px/24px Futura Md BT" : "14px/24px Futura Md BT", color: "#000000", }}>Already a member?</div>
                        <div style={{ font: is_mobile ? "12px/24px Futura Lt BT" : "14px/24px Futura Lt BT", color: "#707070", display:"inline-block"}}>Sign in or register to save your selections and bookings to your account</div>
                        <div onClick={signIn} style={{ font: is_mobile ? "14px/24px Futura Hv BT" : "14px/24px Futura Hv BT", color: "#19BC9B", display:"inline-block", marginLeft:30, cursor:"pointer"}}>
                            <FontAwesomeIcon icon={faUserCircle} style={{marginRight: "5px"}} />Sign in</div>
                                          
                    </div>}
                    
                {booking_info !== undefined && booking_info !== null && 
                 booking_info.name !== undefined && booking_info.name !== null && booking_info.name !== "" && 
                 booking_bundles !== undefined && booking_bundles !== null && booking_bundles !== [] && 
                    <div style={{
                        paddingLeft: padding_l + "%",
                        paddingRight: padding_r + "%",
                        marginTop: is_mobile? 66: "20px"
                    }}>
                        {is_mobile && <CartComponent/>}
                        <div style={{
                            width:is_mobile? "100%":"calc(100% - 346px)",
                            display: "inline-block",
                            verticalAlign: "top"
                        }} className="hotelRecapInfo">
                            <BookingBasicInfo booking_bundles={booking_bundles} booking_info={booking_info}/>
                        </div>
                        {!is_mobile &&<div style={{display:"inline-block"}} className="hotelRecapCart" > <CartComponent/></div>}
                        
                    </div>} 
                </div>
                      
            </div>

        </React.Fragment >
    )
}




const mapStateToProps = state => {
    return {
        timelineStatus: state.FormBank.TimelineWizard.status,
        logo_height: state.Setting.appMenu.logo.height,
        sliderImages: state.FormBank.HotelSearch.establishment.sliderImages,
        lang: state.Setting.lang,
        uuid: state.Member.authModal.uuid,

        is_mobile: state.Setting.is_mobile,
        app_menu_height: state.Setting.appMenu.app_menu_height,
        screenSize: state.Setting.htmlBody.bodyClientWidth,

        establishment: state.FormBank.HotelSearch.establishment,
        booking_info: state.FormBank.BookingPriceline.booking_info,
        booking_bundles: state.FormBank.BookingPriceline.booking_bundles,
        hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
        //check_in: state.FormBank.HotelSearch.lazyLoading.requests['proxyData[check_in]'],
        //check_out: state.FormBank.HotelSearch.lazyLoading.requests['proxyData[check_out]'],
        //rooms: state.FormBank.HotelSearch.lazyLoading.requests['proxyData[rooms]'],
        //adults: state.FormBank.HotelSearch.lazyLoading.requests['proxyData[adults]'],
    }
}

const mapDispatchToProps = {
    fetchHotelEstablishment: Actions.fetchHotelEstablishment,
    fetchHotelBooking: Actions.fetchHotelBooking,
    //setHotelSearchState: Actions.setHotelSearchState,
    //fetchGoogleAndYelpReviewsEstablishment: Actions.fetchGoogleAndYelpReviewsEstablishment,
    //fetchPhotoGalleryEstablishment: Actions.fetchPhotoGalleryEstablishment,
    //fetchExperiencesEstablishment: Actions.fetchExperiencesEstablishment,
    //fetchNeighborhoodEstablishment: Actions.fetchNeighborhoodEstablishment,

    setHotelEstablishmentId: Actions.setHotelEstablishmentId,
    setHotelBookingState: Actions.setHotelBookingState,
    setOpenAuthModal: Actions.setOpenAuthModal,
}

const BookingContainer = connect(mapStateToProps, mapDispatchToProps)(BookingContainer_)
export default BookingContainer
