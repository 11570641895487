import * as Actions from '../../../redux/actions'

import { Discover } from "./Discover.jsx"
import { FooterWhite } from "../Footer/FooterWhite.jsx"
import Header from '../Header/Header.jsx'
import { InfoTrip } from "./InfoTrip.jsx"
import { OtherTrips } from "./OtherTrips.jsx"
import { PhotoGallery } from "./PhotoGallery.jsx"
import PublicTripItineraryMap from "./PublicTripItineraryMap.jsx"
import PublicTripTimeline from "./PublicTripTimeline.jsx"
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BookTrip, SaveTrip } from "./BookTrip.jsx"
import { TripImageBar } from "./TripImageBar.jsx"
import { VideoGallery } from "./VideoGallery.jsx"
import { connect, useDispatch, useSelector } from 'react-redux'
import { HeaderMobile } from '../Header/HeaderMobile'
import { MuverTripMap } from './MuverTripMap'
import { useHistory } from "react-router-dom"
import { URL_REPLACE } from '../Common/RegExValidate'
import { toggleOneclick } from '../FeaturePage/personalizedTrip/personalizedTrip.slice'
import { clearFlightBooking, resetFlightPage, updateFlightData } from '../FlightsPage/services/Flights.slice'
import { getFlightsListThunk } from '../FlightsPage/services/Flights.thunks'
import moment from 'moment'
import { faArrowUp, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { setOpenAuthModal, setTimeLineStatus } from '../../../redux/actions'
import { ALERT_BARS_HANDLE } from '../../../redux/interfaces/types'
import { encryptID } from '../HotelPage/hotelServices'
import { clearHotelBooking } from '../BookingPage/PaymentPage/payment.slice'

export const MuverTripContainer_ = ({authModal, setTripToTimeline,uuidPublic, getPublicTripShare, props, uuidPrivate, trip, is_mobile, lang, getPublicTrip,
    activities = trip?.activities,
    destinations = trip?.tripDestinations,
}) => {
    let padding_l = "8.5"
    let padding_r = "8.5"
    const history = useHistory();
    const dispatch = useDispatch()
    const oneclick = useSelector(state => state.PersonalizedTrip.oneclick);
    const destinationRef = React.useRef(null)
    const [mapDestinations, setMapDestinations] = React.useState(destinations || []);
    const [zoomDestination, setZoomDestination] = React.useState(14)
    const [mapCenterDestination, setMapCenterDestination] = React.useState({
        lat: 0,
        lng: 0
    })
    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const { uuid } = useSelector(state => state.Member.authModal);
    const activeTrip = useSelector(state => state.FormBank.TimelineWizard.activeTrip);
    const activeTripData = useSelector(state =>state.ActiveTrip.trip)
    const timelineHotels = useSelector(state => state.ActiveTrip.timelineHotels);
    const timelineFlights = useSelector(state => state.ActiveTrip.timelineFlights);
    const activeTripId = activeTrip?.tripId;
    const fullCurrency = selectedCurrency ? selectedCurrency.toUpperCase() : "CAD";
    const [openTimeline, setOpenTimeline] = React.useState(false)

    React.useEffect(() => {
        if (destinations && destinations?.length > 0) {
            if (trip?.startingPoint) {
                const copyDest = [...destinations];
                copyDest.unshift(trip?.startingPoint)
                setMapDestinations(copyDest)
            }
            else {
                setMapDestinations(destinations)
            }

        }
    }, [destinations, trip.startingPoint]);



    React.useEffect(() => {
        if(trip && trip?.tripID && !activeTrip?.tripId){
            setTripToTimeline(trip?.tripID, uuidPrivate)
        }
        if ( uuidPrivate) {
            redirectPrivate()
        }
        if(!uuidPrivate){
            //goToDestination() //
            dispatch(setOpenAuthModal(true));
        }
    }, [trip, uuidPrivate]);

    const redirectPrivate = ()=>{
        if(uuidPrivate && trip?.muver?.muverUUID && uuidPrivate !== trip?.muver?.muverUUID){
            goToDestination()
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: "Sorry, but you are not allowed to access this page"
              })
          
        }
        else{
            dispatch(setTimeLineStatus(true))
        }
    }

    const goToDestination = () => {
        const tripDestinations = trip?.tripDestinations
        if(tripDestinations && tripDestinations?.length > 0){
            const destination = tripDestinations[0];
            const url = "/destination/" + destination?.partialUri + "/" + 1 + "/" + destination?.id;
            history.push(url.toLowerCase());
        }
      
    };


    /* fetch trip */
    React.useEffect(() => {

        const shareID = props.match.params?.shareID;
        if (props.match.params.tripID !== undefined &&
            props.match.params.tripID !== null &&
            props.match.params.tripID !== "") {
            getPublicTrip(props.match.params.tripID, lang)
        }
        if (shareID) {
            getPublicTripShare(shareID)
        }
    }, [history?.location, props.match.params.tripID, props.match.params?.shareID])


    if (!isNaN(is_mobile)) {
        padding_l = is_mobile ? "1" : "8.5"
        padding_r = is_mobile ? "1" : "8.5"
    }

    const clearBooking =async ()=>{
        await  dispatch(clearFlightBooking())
        await dispatch(clearHotelBooking())
     }
 
 
    const bookTrip = async () => {
        await clearBooking() ///any previous one
        let hotel = oneclick?.hotel;
        let flight = oneclick?.flight;


        if(timelineHotels?.customHotels && timelineHotels?.customHotels?.length > 0){
            hotel = timelineHotels?.customHotels[0]
        }

        if(timelineHotels?.muvHotels && timelineHotels?.muvHotels?.length > 0){
            hotel = timelineHotels?.muvHotels[0]
        }

        if(timelineFlights&& timelineFlights?.flightInfo?.length > 0){
            flight = timelineFlights?.flightInfo[0]
        }


        if (hotel) {
            dispatch(toggleOneclick(true))
            const city = hotel?.cityName ? hotel.cityName + ', ' : ""
            const state = hotel?.stateName ? hotel.stateName + ', ' : ""
            const country = hotel?.countryName ? hotel.countryName : ""
            const where = city + state + country;

            let url = ""
            if (hotel?.pricelineCityId) {
                const encryptedId =  encryptID( hotel?.pricelineHotelId)
                url = "/hotel-" + hotel?.name.replace(URL_REPLACE, "_") + "-" + encryptedId
                    + "?city_id=" + hotel?.pricelineCityId
                    + "&check_in=" + hotel?.check_in
                    + "&check_out=" + hotel?.check_out
                    + "&rooms=" + 1
                    + "&guests=" + 1
                    + "&hotels=" + hotel?.pricelineHotelId
                    + "&city_lat=" + hotel?.latitude
                    + "&city_lng=" + hotel?.longitude
                    + "&where=" + (where.replace(URL_REPLACE, "_"))

                if (hotel?.ppn_bundle) {
                    url += "&ppn_bundle=" + hotel?.ppn_bundle
                }
            }
            else {

                url = "/hotel-search?city_id=" + null
                    + "&check_in=" + hotel?.check_in
                    + "&check_out=" + hotel?.check_out
                    + "&rooms=" + 1
                    + "&guests=" + 1
                    + "&hotels=" + hotel?.pricelineHotelId
                    + "&city_lat=" + hotel?.latitude
                    + "&city_lng=" + hotel?.longitude
                    + "&where=" + (where.replace(URL_REPLACE, "_"))
            }


            history.push(url.toLowerCase());
        }
        else if (flight) {
            searchFlight()
        }
        else{
            searchHotels()
        }
    }


    const searchHotels = () => {
     
        const city = activeTripData?.destinationName ? activeTripData?.destinationName + ', ' : ""
        const state = activeTripData?.stateName ? activeTripData?.stateName + ', ' : ""
        const country = activeTripData?.countryName ? activeTripData?.countryName : ""
        const where = city + state + country;

        const destination = activeTripData?.tripDestinations?.find((dest)=>dest?.destId === activeTripData?.destinationId)
        const encryptedId =  encryptID(destination?.cityIdPpn)

        const checkOut = activeTripData?.tripStartDate ? moment(activeTripData?.tripStartDate).add(3, 'days').format("YYYY-MM-DD") : moment(new Date()).add(3, 'days').format("YYYY-MM-DD")
        let url = ""
        url = "/hotel-search?city_id=" + encryptedId
            + "&check_in=" + activeTripData?.tripStartDate
            + "&check_out=" + checkOut
            + "&rooms=" + 1
            + "&guests=" + 1
            + "&city_lat=" + destination?.latitude
            + "&city_lng=" + destination?.longitude
            + "&where=" + (where.replace(URL_REPLACE, "_"))



        history.push(url.toLowerCase());
    }

    const searchFlight = () => {
        const flight = oneclick?.flight;
        const flights = [
            {
                "airportFrom": {
                    "country": "",
                    "coordinate": "",
                    "city": flight?.departure_city,
                    "latitude": "",
                    "city_code": flight?.departure_iata,
                    "type": "main",
                    "country_code": "",
                    "cityid_t": "",
                    "state_name": "",
                    "cityid_ppn": "",
                    "rank": "",
                    "cityid_air": "",
                    "state_code": "",
                    "longitude": "",
                    "score": "",
                    "iata": flight?.departure_iata,
                    "airport": "All Airports"
                },
                "airportTo": {
                    "country": "",
                    "coordinate": "",
                    "city": flight?.arrival_city,
                    "latitude": "",
                    "city_code": flight?.arrival_iata,
                    "type": "main",
                    "country_code": "",
                    "cityid_t": "",
                    "state_name": "",
                    "cityid_ppn": "",
                    "rank": "",
                    "cityid_air": "",
                    "state_code": "",
                    "longitude": "",
                    "score": "",
                    "iata": flight?.arrival_iata,
                    "airport": "All Airports"
                },
                "departureDateFormat": flight?.departure_date ? new Date(moment(flight?.departure_date).format("YYYY-MM-DD 00:00")) : new Date().toDateString(),
                "departureDate": flight?.departure_date ? new Date(moment(flight?.departure_date).format("YYYY-MM-DD 00:00")) : new Date().toDateString(),
                "availableSelectDate": new Date().toDateString(),
                "positionId": "d-0"
            }]
        const dataToBookingFlights = {
            flightMode: 'one_way',
            cabinClass: 'economy',
            numberAdult: '1',
            numberChild: '0',
            flights: flights,
            currency: fullCurrency

        }


        dispatch(updateFlightData(dataToBookingFlights))
        dispatch(resetFlightPage())
        dispatch(getFlightsListThunk(dataToBookingFlights))

        history.push('/flight/search')
    }


    return (
        <div>
            <>

                {!is_mobile ? <Header bg_color={"#FFFFFF"} text_color={"var(--frameBlack)"} /> :
                    <HeaderMobile bg_color={"#FFFFFF"} text_color={"var(--frameBlack)"} />}
            </>
            <TripImageBar setOpenTimeline={setOpenTimeline} className="bg_white" from={"trip_public"} />
            <div style={{
                paddingLeft: padding_l + "%",
                paddingRight: padding_r + "%",
            }}>
                <BookTrip />
                {trip !== undefined
                    && trip.tripDescription === undefined
                    && trip.tripDescription === ""
                    && trip.tripCompanions !== undefined
                    && trip.tripCompanions.length > 0 &&
                    <React.Fragment>
                        <hr className="line_separator_w margin_top_50 margin_bottom_50" />
                        <InfoTrip />
                    </React.Fragment>}


                <div className="futura_hv_bt_30_32_black pl-2 margin_bottom_50">Trip Map</div>

                {window.google !== undefined && destinations &&
                    destinations?.length > 0 && <MuverTripMap
                        tripId={trip?.tripID}
                        destinations={mapDestinations}
                        zoom={zoomDestination}
                        setZoom={setZoomDestination}
                        mapCenter={mapCenterDestination}
                        setMapCenter={setMapCenterDestination}
                        refMap={destinationRef} />}

                <hr className="line_separator_w margin_top_50 margin_bottom_50" />


                {window.google !== undefined && <PublicTripItineraryMap />}
                {trip.tripPhotoGallery !== undefined && trip.tripPhotoGallery.length > 0 &&
                    <React.Fragment>
                        <hr className="line_separator_w margin_top_50 margin_bottom_50" />
                        <div className="futura_hv_bt_30_32_black pl-2 margin_bottom_50">Photo Gallery</div>
                        <PhotoGallery />
                    </React.Fragment>}
                {trip.tripPhotoGallery !== undefined && trip.tripPhotoGallery.length > 0 &&
                    <React.Fragment>
                        <hr className="line_separator_w margin_top_50 margin_bottom_50" />
                        <div className="futura_hv_bt_30_32_black pl-2 margin_bottom_50">Videos</div>
                        <VideoGallery />
                    </React.Fragment>}
                {trip.tripDestinations !== undefined
                    && trip.tripDestinations.length > 0
                    && trip.tripDestinations[0] !== undefined &&


                    <React.Fragment>
                        < hr className="line_separator_w margin_top_50 margin_bottom_50" />
                        <div className="futura_hv_bt_30_32_black pl-2 margin_bottom_50"
                            style={{
                                font: "18px/20px Futura Hv BT"
                            }}>Timeline</div>
                        <PublicTripTimeline openTimeline={openTimeline} setOpenTimeline={setOpenTimeline} />
                    </React.Fragment>}



                <div style={{
                    paddingLeft: padding_l + "%",
                    paddingRight: padding_r + "%",
                }}>

                    <div className="top_bar_container padding_left_right_white" onClick={bookTrip} style={{
                        cursor: "pointer", marginTop: 30,
                        background: 'var(--mainGreen)', height: 60, borderRadius: 15,
                        width:is_mobile? "100%": "81%", textAlign: 'center',
                       marginLeft: is_mobile? 0: "150px",
                    }}>
                        <div style={{
                            font: "22px/50px Futura Md BT",
                            color: "var(--mainWhite)",
                        }} >
                            Book this trip
                        </div>
                    </div>

                    <div className=" margin_bottom_50"
                    onClick={()=>{
                        window.scrollTo(0, 0);
                    }}
                            style={{
                                width: is_mobile? "100%": "81%",
                                marginLeft: is_mobile? 0: "150px",
                                font: "16px/10px Futura Md BT",
                                color: "var(--mainBlue)",
                                marginTop: 20,
                                cursor: "pointer",
                                textAlign:"right"
                            }}>Go on top to Edit, Share or Print this trip <FontAwesomeIcon icon={faChevronUp} style={{ marginRight: "5px" }} /></div>

                </div>
                {/* <hr className="line_separator_w margin_top_50 margin_bottom_50" />
                                    <div className="futura_hv_bt_30_32_black pl-2 margin_bottom_50">Travel journal</div>
                                    <TravelJournal screenSize={this.state.screenSize} /> */}
                {trip.otherTrips !== undefined && trip.otherTrips.length > 0 &&
                    <React.Fragment>
                        <hr className="line_separator_w margin_top_50 margin_bottom_50" />
                        <div className="futura_hv_bt_30_32_black pl-2 margin_bottom_50">Other trips you might like</div>
                        <OtherTrips from={"trip_public"} textColor={"text_main_black"} />
                        <div className="margin_top_50 margin_bottom_50"></div>

                    </React.Fragment>}
            </div>
            <div style={{
                paddingLeft: padding_l + "%",
                paddingRight: padding_r + "%",
                background: "#efefef",
                marginTop: is_mobile ? "50px" : "150px",
            }}>
                <Discover />
            </div>

            <FooterWhite />
        </div>
    )
}

const mapStateToProps = (state) => ({
    trip: state.PublicTrip.trip,
    is_mobile: state.Setting.is_mobile,
    authModal: state.Member.authModal,
    lang: state.Setting.lang,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    uuidPublic: state.Social.member_uuid,
    uuidPrivate: state.Member.authModal.uuid,

})

const mapDispatchToProps = {
    getPublicTrip: Actions.getPublicTrip,
    getPublicTripShare: Actions.getPublicTripShare,
    setTripToTimeline: Actions.setTripToTimeline,
}

const MuverTripContainer = connect(mapStateToProps, mapDispatchToProps)(MuverTripContainer_)
export default MuverTripContainer
