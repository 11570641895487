import React, { useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { RightArrowIcon, CircleDownIcon, CircleUpIcon } from "../Common/SVGBank"
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faHotel, faRuler, faBed } from '@fortawesome/free-solid-svg-icons'
import * as Actions from '../../../redux/actions'
import { priceline_image_boxing, loadingImage, URL_REPLACE } from '../Common/RegExValidate'
import { Image } from 'react-bootstrap';

import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon';
import { Action } from 'rxjs/internal/scheduler/Action'
import { ActionsObservable } from 'redux-observable'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom"
import { updateHotelPPn, updateOneclickPackageIndex, updatePackageData, updateUseAiHotel } from '../FeaturePage/personalizedTrip/personalizedTrip.slice'
import moment from 'moment'
import { resetFlightPage, updateFlightData } from '../FlightsPage/services/Flights.slice'
import { getFlightsListThunk } from '../FlightsPage/services/Flights.thunks'

import { FetchCachedPullPrice } from '../BookingPage/PaymentPage/payment.thunk'
import { encryptID } from './hotelServices'

String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
}

const Rooms_ = ({ establishment, setOpenAuthModal, hotel_form_state, lang, isMobile, uuid, addBundleToHotelBooking, setHotelBookingState, booking_bundles, booking_info }) => {
    const { generalInfo } = establishment
    const { establishmentPricelineID, room_data, amenity_data } = generalInfo
    const hotel_id = establishmentPricelineID
    const history = useHistory()
    const oneclick = useSelector(state => state.PersonalizedTrip.oneclick.active)
    const oneclickFlight = useSelector(state => state.PersonalizedTrip.oneclick.flight)
    const packageData = useSelector(state => state.PersonalizedTrip.oneclick.package.data)
    const packageIndex = useSelector(state => state.PersonalizedTrip.oneclick.package.index)
    const dispatch = useDispatch()
    const showRooms = room_data !== undefined ? true : false
    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const fullCurrency = selectedCurrency ? selectedCurrency.toUpperCase() : "CAD";
    const stayTrip = useSelector(state => state.PersonalizedTrip.stayTrip)
    var searchParams = new URLSearchParams(document.location.search.substring(1));
    const promoCode = searchParams.get("PROMO-CODE") ||  searchParams.get("promo-code");
    const [hideRooms, setHideRooms] = React.useState(false)
    const cachedHotel = useSelector(state => state.HotelBooking.cachedHotel?.data);
    const useSmop = promoCode && promoCode?.toLowerCase() === "muv";
    const useMer = promoCode && promoCode?.toLowerCase() === "mer";
    const useMobileSmop = promoCode && promoCode?.toLowerCase() === "mob";
    const useAiCode = promoCode && promoCode?.toLowerCase() === "ai";
    const useDiscount = promoCode && promoCode?.toLowerCase()?.includes("9801") ? promoCode?.split('9801')[1] : null;
    const discountAmt = useDiscount ? Number(useDiscount) : null;

    const useCached = (useSmop || useDiscount) && cachedHotel; //disable
    const prepaidCached = useCached && cachedHotel?.payment_type === "prepaid";
    const postpaidCached = useCached && cachedHotel?.payment_type !== "prepaid";
    const cachedSubtotal = cachedHotel && useCached ? Number(cachedHotel?.display_sub_total || 0) : null;
    const prepaidCachedTotal = cachedHotel && prepaidCached ? Number(cachedHotel?.display_sub_total || 0) + Number(cachedHotel?.prepaid_display_total || 0) : 0;
    const cachedTotal = cachedHotel ? cachedHotel?.prepaid_display_total ? Number(cachedHotel?.display_sub_total) + Number(cachedHotel?.prepaid_display_total) : Number(cachedHotel?.display_sub_total) + Number(cachedHotel?.postpaid_display_total) : null;
    const cachedTaxes = cachedHotel && cachedHotel?.prepaid_display_total ? Number(cachedHotel?.prepaid_display_total) : null;


    let currRoomId = ""
    let newRoom = true
    let optionNo = 1
    let prevOptionsNum = 0
    let prevRoomId = ""

    const boardType = {
        NONE: "No breakfast",
        BREAKFAST: "Breakfast",
        ALL_INCLUSIVE: "All inclusive"
    }

    const widthContainer = 300
    const heightContainer = 250

    const useStyles = makeStyles({
        optionsHdr: {
            color: "rgba(0, 0, 0, 1)",
            font: "18px/28px Futura Hv BT",
        },
        optionsRooms: {
            color: "rgba(0, 0, 0, 1)",
            font: "16px/28px Futura Hv BT",
        },
        optionsText: {
            color: "#929292",
            font: "14px/18px Futura Lt BT",
        },
        optionsGreenText: {
            color: "rgba(28, 188, 155, 1)",
            font: "14px/28px Futura Lt BT",
        },
        optionsBtn: {
            //backgroundColor: "rgba(28, 188, 155, 1)",
            color: "rgba(255, 255, 255, 1)",
            font: "14px/14px Futura Hv BT",
            width: "150px",
            height: "44px",
            padding: "14px 22px",
            cursor: "pointer",
            backgroundColor: 'var(--mainGreen)',
        },
        optionBlock: {
            marginLeft: isMobile ? 0 : widthContainer,
        }
    });

    const isLoggedIn = () => {
        return uuid && uuid.length > 0
    }


    const classes = useStyles();

    const populateFlight = (flight) => {
        if (flight) {

            const flights = [
                {
                    "airportFrom": {
                        "country": "",
                        "coordinate": "",
                        "city": flight?.departure_city,
                        "latitude": "",
                        "city_code": flight?.departure_iata,
                        "type": "main",
                        "country_code": "",
                        "cityid_t": "",
                        "state_name": "",
                        "cityid_ppn": "",
                        "rank": "",
                        "cityid_air": "",
                        "state_code": "",
                        "longitude": "",
                        "score": "",
                        "iata": flight?.departure_iata,
                        "airport": "All Airports"
                    },
                    "airportTo": {
                        "country": "",
                        "coordinate": "",
                        "city": flight?.arrival_city,
                        "latitude": "",
                        "city_code": flight?.arrival_iata,
                        "type": "main",
                        "country_code": "",
                        "cityid_t": "",
                        "state_name": "",
                        "cityid_ppn": "",
                        "rank": "",
                        "cityid_air": "",
                        "state_code": "",
                        "longitude": "",
                        "score": "",
                        "iata": flight?.arrival_iata,
                        "airport": "All Airports"
                    },
                    "departureDateFormat": flight?.departure_date ? new Date(moment(flight?.departure_date).format("YYYY-MM-DD 00:00")) : new Date().toDateString(),
                    "departureDate": flight?.departure_date ? new Date(moment(flight?.departure_date).format("YYYY-MM-DD 00:00")) : new Date().toDateString(),
                    "availableSelectDate": new Date().toDateString(),
                    "positionId": "d-0"
                }]
            const numPassengers = flight?.flight_json?.itinerary_data?.length > 0 &&
                flight?.flight_json?.itinerary_data[0]?.price_details?.number_of_passengers;
            const dataToBookingFlights = {
                flightMode: flight?.search_type || 'one_way',
                cabinClass: 'economy',
                numberAdult: numPassengers,
                numberChild: '0',
                flights: flights,
                currency: fullCurrency

            }


            dispatch(updateFlightData(dataToBookingFlights))
            dispatch(resetFlightPage())
            dispatch(getFlightsListThunk(dataToBookingFlights))

            history.push('/flight/search')
        }



    }


    const navigateHotel = (hotel) => {
        const city = hotel?.cityName ? hotel.cityName + ', ' : ""
        const state = hotel?.stateName ? hotel.stateName + ', ' : ""
        const country = hotel?.countryName ? hotel.countryName : ""
        const where = city + state + country;

        let url = ""
        if (hotel?.pricelineCityId) {
            const encryptedId = encryptID(hotel?.pricelineHotelId)
            url = "/hotel-" + hotel?.name.replace(URL_REPLACE, "_") + "-" + encryptedId
                + "?city_id=" + hotel?.pricelineCityId
                + "&check_in=" + hotel?.check_in
                + "&check_out=" + hotel?.check_out
                + "&rooms=" + 1
                + "&guests=" + 1
                + "&hotels=" + hotel?.pricelineHotelId
                + "&city_lat=" + hotel?.latitude
                + "&city_lng=" + hotel?.longitude
                + "&where=" + (where.replace(URL_REPLACE, "_"))

            if (hotel?.ppn_bundle) {
                url += "&ppn_bundle=" + hotel?.ppn_bundle
            }
        }
        history.push(url.toLowerCase());
    }




    const handleAddRoomToBooking = async ({ lang, uuid, trip_id, hotel_id, ppn_book_bundle, check_in, check_out, rooms, adults, booking_bundles }) => {

        // if booking_info.establishmentPricelineID !== hotel_id then clear booking
        /*   if(booking_info.establishmentPricelineID !== undefined && booking_info.establishmentPricelineID !== hotel_id) {
               console.log("clear");
               //clearHotelBooking();
               booking_bundles=[]
               setHotelBookingState({
                   hotel_id, 
                   ppn_book_bundle, 
                   check_in, 
                   check_out, 
                   rooms,
                   adults
               })
           }
           */

        addBundleToHotelBooking({ hotel_id, ppn_book_bundle, check_in, check_out, rooms, adults, booking_bundles })


        //setHotelEstablishmentId(pricelineId)
        //setSwitchContainer(member_area_router.establishment)

        if (useAiCode) {
            dispatch(updateUseAiHotel(true))
        }
        if (oneclick) {
            const hotelPackages = packageData && packageData?.length > 0 ? packageData?.filter((data) => data?.type === "hotel") : null
            if (packageData && packageIndex <= packageData?.length - 1) { //continue loop
                const nextIndex = packageIndex + 1;
                let updatePackage = packageData.map(a => ({ ...a }));;

                if (ppn_book_bundle) {
                    updatePackage[packageIndex]["ppn_book_bundle"] = ppn_book_bundle;
                    dispatch(updatePackageData(updatePackage)) ///add ppn bundle
                }

                dispatch(updateOneclickPackageIndex(nextIndex))
                const nextData = packageData[nextIndex];

                if (nextData?.type === "hotel") {
                    navigateHotel(nextData)
                }
                if (nextData?.type === "flight") {
                    populateFlight(nextData)
                }

            }



            // if (oneclick && ppn_book_bundle && oneclickFlight) {
            //     dispatch(updateHotelPPn(ppn_book_bundle))
            //     populateFlight()
            // }
        }

        if (useOTP || useSmop || useMer || useDiscount || useAiCode) {

            const data = {
                hotelid_ppn: hotel_id,
                check_in: hotel_form_state.check_in,
                check_out: hotel_form_state.check_out
            }

            if (hotel_id) {
                dispatch(FetchCachedPullPrice(data)) ///

            }



            history.push("/booking"
                + (trip_id ? "-" + trip_id : "")
                + "?check_in=" + hotel_form_state.check_in
                + "&check_out=" + hotel_form_state.check_out
                + "&rooms=" + (hotel_form_state.rooms ? hotel_form_state.rooms : 1)
                + "&adults=" + (hotel_form_state.adults ? hotel_form_state.adults : 1)
                + (ppn_book_bundle ? "&ppn_book_bundle=" + ppn_book_bundle : "")
                + "&rate=smop")
        }

        else {
            history.push("/booking"
                + (trip_id ? "-" + trip_id : "")
                + "?check_in=" + hotel_form_state.check_in
                + "&check_out=" + hotel_form_state.check_out
                + "&rooms=" + (hotel_form_state.rooms ? hotel_form_state.rooms : 1)
                + "&adults=" + (hotel_form_state.adults ? hotel_form_state.adults : 1)
                + (ppn_book_bundle ? "&ppn_book_bundle=" + ppn_book_bundle : ""))
        }

    }

    let [, setState] = React.useState();

    //const [openId, setOpenId] = React.useState("")
    const [openId, setOpenId] = React.useState([])

    const showOptions = (id) => {
        let arry = openId
        arry.push(id)
        setOpenId(arry)
        setState({})
    }
    const hideOptions = (id) => {
        let arry = openId
        arry = arry.filter(item => item !== id)
        setOpenId(arry)
        setState({})
    }

    function randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }


    const hotelRooms = room_data?.room_data;
    const photos = room_data?.photo_data;

    const defaultVals = hotelRooms ? Array.from(Array(hotelRooms.length).keys(), n => 2) : null;
    const [rateLimit, setRateLimit] = React.useState(defaultVals);
    const [selectedOption, setSelectedOption] = React.useState(null);

    const AMENITY_COUNT = 5;
    const [amenityLimit, setAmenityLimit] = React.useState(AMENITY_COUNT);


    const changeAmenityLimit = (limit) => {
        setAmenityLimit(limit)
    }

    const openPopup = () => {
        setOpenAuthModal(true)
    }




    const sortRoomsByPrice = () => {
        let copyRooms = [...hotelRooms] || [];
        const isLoggedin = isLoggedIn()

        copyRooms.forEach((room) => {
            if (isLoggedin || useSmop) {
                room['rate_data'] = room?.rate_data?.sort((a, b) => a?.price_details?.display_all_in_total - b?.price_details?.display_all_in_total)
            }
            else { //sort logged out
                room['rate_data'] = room?.rate_data?.sort((a, b) => {
                    if (a?.rate_type === "MER" && b?.rate_type === "MER") {
                        return a?.price_details?.display_all_in_total - b?.price_details?.display_all_in_total
                    }
                    return a?.benchmark_price_details?.display_price - b?.benchmark_price_details?.display_price
                })
            }

        }) ///sort rate



        //sort rooms
        if (isLoggedin || useSmop) {
            copyRooms = copyRooms?.sort((a, b) => a?.rate_data[0]?.price_details?.display_all_in_total - b?.rate_data[0]?.price_details?.display_all_in_total)
        }
        else { //sort logged out
            copyRooms = copyRooms?.sort((a, b) => {
                if (a?.rate_data[0]?.rate_type === "MER" && b?.rate_data[0]?.rate_type === "MER") {
                    return a?.rate_data[0]?.price_details?.display_all_in_total - b?.rate_data[0]?.price_details?.display_all_in_total
                }
                return a?.rate_data[0]?.benchmark_price_details?.display_price - b?.rate_data[0]?.benchmark_price_details?.display_price
            })

            //    console.log(copyRooms,282)
            //     let mer =  []
            //     let smop = []
            //    copyRooms.forEach((data, index)=>{
            //     if(data?.rate_data[0]?.rate_type === "SMOP"){
            //         smop.push(data)
            //     }
            //     else{
            //         mer.push(data)
            //     }
            //    })


            //    copyRooms = [...mer,...smop]

        }





        return copyRooms;
    }

    const sortedRooms = sortRoomsByPrice();



    const useOTP = promoCode && promoCode?.toLowerCase() === "otp";


    React.useEffect(() => {
        if (hotelRooms && hotelRooms?.length > 0) {
            let smop = false;
            hotelRooms.forEach((room) => {
                const rates = room?.rate_data || [];

                rates.forEach((rate) => {
                    if (rate?.rate_type === "SMOP") {
                        smop = true;
                    }
                })
            }
            )
            const hide = smop && !isLoggedIn() && useOTP
            setHideRooms(hide)
        }
    }, [hotelRooms])

    React.useEffect(() => {
        if (uuid && uuid.length > 0) {
            setHideRooms(false)
        }
    }, [uuid]) //signs in 


    if (hideRooms) {
        return null
    } //dont show rooms until logged in

    return (
        <div>
            <div style={{ font: "24px/16px Futura Hv BT", color: "#000000" }}>Choose Room</div>
            <div>
                <div style={{ font: "16px/18px Futura Md BT", color: "#000000" }}>

                    {showRooms && hotelRooms && sortedRooms?.map((room, idx) => {

                        newRoom = (currRoomId !== room.id)
                        prevRoomId = newRoom ? currRoomId : prevRoomId
                        currRoomId = newRoom ? room.id : currRoomId
                        prevOptionsNum = newRoom ? optionNo : optionNo + 1
                        optionNo = newRoom ? 1 : optionNo + 1


                        const ppn_book_bundle = room.ppn_book_bundle;

                        // if(room.rate_type === "SMOP"){
                        //     console.log(room,"room") 
                        // }


                        const averageSleeps = room?.rate_data?.reduce((total, next) => total + next?.occupancy_limit, 0) / room?.rate_data?.length;

                        const titleArray = room?.title.split(',');
                        const roomName = titleArray ? titleArray[1] : null;

                        const toggleRateLimit = () => {
                            setSelectedOption((oldId) => {
                                return idx === oldId ? null : idx;
                            })

                        }

                        const photoIndex = photos && photos?.length > 0 ? randomIntFromInterval(0, photos?.length) : 1;



                        return (<div key={idx} >
                            {newRoom &&
                                <React.Fragment>


                                    <div style={{ width: "100%", height: 30, float: "none", clear: "both", borderBottom: idx !== 0 ? "2px solid rgb(232, 232, 232)" : "0px none", marginBottom: 15 }}></div>
                                    <div style={{ font: "18px/24px Futura Hv BT", color: "#000000", marginBottom: 10 }}>{room?.title?.replace(".", "")}</div>
                                    {room && room?.description && <div style={{ font: "16px/24px Futura Lt BT", color: "#929292", marginBottom: 10 }}>{room?.description?.replace(".", "")}</div>}
                                    <div>
                                        {/* {roomName && <React.Fragment>
                                            <FontAwesomeIcon icon={faBed} style={{ marginRight: "5px" }} />
                                            <span className={classes.optionsRooms}>{roomName + " bed"}</span>
                                        </React.Fragment>} */}
                                        {room.room_square_footage > 0 && <React.Fragment>
                                            <FontAwesomeIcon icon={faRuler} style={{ marginLeft: "15px", marginRight: "5px" }} /><span className={classes.optionsRooms}>{room.room_square_footage + " sq ft"}</span>
                                        </React.Fragment>}
                                        {averageSleeps && <React.Fragment>
                                            <FontAwesomeIcon icon={faUsers} style={{ marginLeft: "15px", marginRight: "5px" }} /><span className={classes.optionsRooms}>Sleeps {Math.round(averageSleeps)}</span>
                                        </React.Fragment>}


                                    </div>
                                    {photos && photos?.length > 0 && <div style={{ width: widthContainer, position: "static", float: isMobile ? "none" : "left" }}>
                                        <Image style={{ objectFit: "cover", objectPosition: "center", width: widthContainer, height: heightContainer }}
                                            alt="establishment" src={photos[idx + 1] ||
                                                "https://amhqajbken.cloudimg.io/v7/_muvestab_/000_c…on/grayBackgroundCamera.jpg?func=crop&w=359&h=300"}
                                        />
                                    </div>}


                                </React.Fragment>
                            }

                            {room && room?.rate_data && room?.rate_data?.slice(0, selectedOption === idx ? room?.rate_data?.length : 2)?.map((rate, indexRate) => {
                                const specialPrice = (isLoggedIn() || stayTrip || useSmop || useMobileSmop) && rate?.rate_type === "SMOP" && rate.benchmark_price_details ? true : false;
                                const signInforSpecial = !isLoggedIn() && !stayTrip && !useSmop && !useMobileSmop && rate?.rate_type === "SMOP" && rate.benchmark_price_details ? true : false;


                                let ratePriceText = useMobileSmop && specialPrice ? "mobile price" : specialPrice ? "müver special price" : signInforSpecial ? "sign in for müver special price" : "per night";

                                let rateTextColor = specialPrice ? "#0D3A7D" : signInforSpecial ? "#19BC9B" : "#929292";

                                let roomPrice = rate?.benchmark_price_details?.display_price;
                                let roomDisplayPrice = rate.price_details.display_price;
                                // let roomTax = rate?.price_details?.display_taxes;
                                let roomTax = rate?.price_details?.mandatory_fee_details?.breakdown?.prepaid?.display_total;

                                // if(useDiscount && indexRate === 0 && idx === 0 && rate?.rate_type !== "SMOP"){ //for cheapest rate
                                if (useDiscount && rate?.rate_type !== "SMOP") { //for cheapest rate
                                    roomPrice = (roomPrice - ((discountAmt / 100) * roomPrice))
                                    roomDisplayPrice = (roomDisplayPrice - ((discountAmt / 100) * roomDisplayPrice))
                                    roomTax = (roomTax - ((discountAmt / 100) * roomTax))
                                }

                                const amenityLength = rate?.rate_amenity_data.length;
                                const hasBreakfast = rate?.rate_amenity_data && rate?.rate_amenity_data?.includes("FREE BREAKFAST" || "Free Breakfast")
                                const amenities = rate?.rate_amenity_data && hasBreakfast ? rate?.rate_amenity_data?.filter((item) => item?.toLowerCase() !== "free breakfast") : rate?.rate_amenity_data;
                                const days = diffDays(hotel_form_state?.check_out, hotel_form_state.check_in) ?? 1;
                                const taxes = Number(roomTax / days).toFixed(2);

                                const postPaid = rate?.payment_type === "POSTPAID"
                                const prePaid = rate?.payment_type === "PREPAID"


                                return (
                                    <div className={classes.optionBlock} key={indexRate} >

                                        <div style={{ marginLeft: isMobile ? 0 : 20, marginTop: 10, font: "18px/28px Futura Hv BT", color: "#000000" }}>Option {indexRate + 1}</div>
                                        {/* <div style={{ marginLeft: 20, width: "29%", display: "inline-block", verticalAlign: "top" }}>
                                            <div><span className={classes.optionsText}>{rate.title}</span></div>
                                            <div><span className={classes.optionsText}>{boardType[rate.board_type]}</span></div> 
                                        </div> */}
                                        {<div style={{ width: "24%", marginLeft: isMobile ? 0 : 20, display: "inline-block", verticalAlign: "top" }}>
                                            {rate.payment_type && <div><span className={classes.optionsText}>{rate.payment_type === "POSTPAID" ? "Pay later" : "Pay now"}</span></div>}
                                            {hasBreakfast && <div><span className={classes.optionsText}>Free Breakfast</span></div>}
                                            {rate.refund_type && <div><span className={classes.optionsText}>{rate.refund_type === "NONE" ? "Non-Cancellable" : "Cancellable"}</span></div>}
                                            {rate.refund_type && <div><span className={classes.optionsText}>{rate.refund_type === "NONE" ? "Non-Refundable" : "Refundable"}</span></div>}
                                            {rate.occupancy_limit > 0 && <div><span className={classes.optionsText}>Max Occupancy: {rate.occupancy_limit}</span></div>}

                                        </div>}
                                        <div className={classes.optionsText} style={{ width: "22%", marginLeft: isMobile ? 0 : 20, display: "inline-block", verticalAlign: "top", textTransform: "capitalize" }}>
                                            {amenities !== undefined && amenities !== null && amenities?.length > 0 && amenities?.slice(0, amenityLimit).map((amenity, i) => {
                                                return (<div key={i} style={{ textTransform: "capitalize" }}>{amenity?.toLowerCase()}</div>)
                                            })}
                                            {amenityLength > AMENITY_COUNT && <div onClick={() => {
                                                changeAmenityLimit(amenityLimit === AMENITY_COUNT ? amenityLength : AMENITY_COUNT);
                                            }} style={{ color: "var(--mainGreen)", cursor: "pointer", marginTop: 5, }}> {amenityLimit === AMENITY_COUNT ? "more" : "less"}</div>}
                                        </div>






                                        <div style={{ width: "100%", display: isMobile ? "block" : "flex", verticalAlign: "top", marginLeft: isMobile ? 0 : 20, marginTop: 15, marginBottom: isMobile ? 20 : 0 }}>
                                            <div className={classes.optionsGreenText} style={{ width: '51%', float: isMobile ? 'none' : 'left', paddingTop: 20 }}>{rate.available_rooms ? `We have ${rate.available_rooms} rooms left` : ""}</div>
                                            <div style={{ display: isMobile ? "block" : "inline-grid", width: isMobile ? "100%" : "27%", textAlign: 'right', paddingRight: 15, marginTop: isMobile ? -50 : -4 }}><span className={classes.optionsHdr}>

                                                {specialPrice && <span style={{ font: "14px/28px Futura Lt BT", color: 'red', textDecoration: 'line-through', marginRight: 12 }}>{rate.price_details.display_symbol + (roomPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</span>}
                                                {cachedSubtotal > 0 && idx === 0 && indexRate === 0 ?
                                                    rate.price_details.display_symbol + (cachedSubtotal?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                                                    :
                                                    signInforSpecial ? rate.price_details.display_symbol + (roomPrice?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")) : rate.price_details.display_symbol + (roomDisplayPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","))}
                                            </span>

                                                {cachedTaxes > 0 && idx === 0 && indexRate === 0 ? <div className={classes.optionsText} style={{ marginTop: -6 }}>
                                                    +{rate.price_details.display_symbol}{Number(cachedTaxes).toFixed(2)} taxes & fees</div> :

                                                    taxes > 0 && <div className={classes.optionsText} style={{ marginTop: -6 }}>
                                                        +{rate.price_details.display_symbol}{Number(taxes).toFixed(2)} taxes & fees</div>}
                                                <div className={classes.optionsText} onClick={() => signInforSpecial ? openPopup() : null} style={{ color: rateTextColor, cursor: signInforSpecial ? "pointer" : "auto" }}>{ratePriceText}</div></div>


                                            {<div>
                                                {postPaid && <div className={classes.optionsGreenText} style={{ lineHeight: "10px" }}>Book now, pay later</div>}
                                                <div onClick={() => signInforSpecial ? null : handleAddRoomToBooking({ lang, uuid, ppn_book_bundle: rate.ppn_bundle, booking_bundles, hotel_id, ...hotel_form_state })
                                                }
                                                    className={classes.optionsBtn}
                                                    style={{ opacity: signInforSpecial ? 0.4 : 1, marginLeft: isMobile ? "auto" : 0, display: "flex", height: 50, marginTop: 5 }} >Book <RightArrowIcon width={15} height={15} style={{ marginLeft: "40px" }} /></div>
                                                {useAiCode && <div className="" 
                                                onClick={() => signInforSpecial ? null : handleAddRoomToBooking({ lang, uuid, ppn_book_bundle: rate.ppn_bundle, booking_bundles, hotel_id, ...hotel_form_state })}
                                                style={{
                                                    color: "var(--mainGreen)", font: "10px/10px Futura LT BT",
                                                    marginTop:5, cursor:"pointer",textAlign:"right"
                                                }}>
                                                    Book & get free AI-personalized trip
                                                </div>}
                                            </div>}

                                        </div>



                                    </div>
                                )
                            })}
                            {room?.rate_data && room?.rate_data?.length > 2 && <div className="" style={{
                                color: "var(--mainGreen)",
                                marginLeft: isMobile ? 0 : 317, marginTop: isMobile ? 15 : "5px"
                            }}>
                                {selectedOption === idx && <div style={{ cursor: "pointer" }} onClick={() => toggleRateLimit()}> <CircleUpIcon /> Hide more options</div>}
                                {selectedOption !== idx && <div style={{ cursor: "pointer" }} onClick={() => toggleRateLimit()}> <CircleDownIcon /> Show more options</div>}
                            </div>}



                        </div>)
                    })}

                </div>
            </div>
            <div style={{ width: "100%", height: 0, float: "none", clear: "both" }}></div>
        </div>
    )
}

const mapStateToProps = (state) => ({

    establishment: state.FormBank.HotelSearch.establishment,
    hotel_form_state: state.FormBank.HotelSearch.hotel_form_state,
    isMobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

    booking_bundles: state.FormBank.BookingPriceline.booking_bundles,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    booking_info: state.FormBank.BookingPriceline.booking_info,
})

const mapDispatchToProps = {
    addBundleToHotelBooking: Actions.addBundleToHotelBooking,
    setHotelBookingState: Actions.setHotelBookingState,
    setOpenAuthModal: Actions.setOpenAuthModal,
}

const Rooms = connect(mapStateToProps, mapDispatchToProps)(Rooms_)
export default Rooms


export const diffDays = (d1, d2) => {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
}